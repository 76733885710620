import React, { Component } from 'react'
import EventDetailsMini from './EventDetailsMini';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class EventsList extends Component {

    constructor(props){
        super(props);
        
        var textualDate = new Intl.DateTimeFormat("it-IT", {
            year: "numeric",
            month: "long",
            day: "numeric"
          }).format(props.date);

        this.state = { events: props.events, date: textualDate };
    }

    cancel = () => {

        window.location.reload();
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>

          
            <div style={{marginTop: '35px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <div style={{marginLeft: '0px',  height: '25px', borderRadius: '8px', width: '200px', color: _secondaryTextColor, fontWeight: 'normal', fontSize: '16px', textAlign: 'center'}}>
            📆   {this.state.date}          
            </div>        
            </div>
              
                <div style={{ marginTop: '50px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}> {i18n.t('event.list')}</div>


                 <div style={{ marginTop: '30px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                {
           this.state.events.map((event, i) =>
                    <EventDetailsMini key={i} info={event} />
                )
                }
              
              
                
                </div>  
                 </div>


                 <div onClick={this.cancel} style={{ marginTop: '50px', cursor: 'pointer', fontSize: '14px', color: _mainTextColor }}> {i18n.t('cancel')}</div>
               
            </div>
        )
    }
}

export default EventsList
