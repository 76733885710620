import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import i18next from 'i18next';

export class LangOption extends Component {

    constructor(props){
        super(props);
        
        var currentLang = i18next.language;
        var currentLangSymbol = '🇬🇧';
        if(currentLang == 'it'){
            currentLangSymbol = '🇮🇹';
        }else if(currentLang == 'ja'){
            currentLangSymbol = '🇯🇵';
        }else if(currentLang == 'es'){
            currentLangSymbol = '🇪🇸';
        }

        this.state = { currentLang: currentLang, currentLangSymbol: currentLangSymbol, changeLang: false };
    }

    changeLang = () => {
        this.setState({ changeLang: true });
    }

    setLang = (lang) => {
        i18next.changeLanguage(lang);
        window.location.reload();
    }

  render() {

    let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        
    return (
      <div>
          
        <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
        <div onClick={this.changeLang} style={{ width: '30px', cursor: 'pointer', textAlign: 'left', marginTop: '0px', fontSize: '20px', fontWeight: 'normal'}}>
            {this.state.currentLangSymbol}
        </div>       
        </div>


        <Drawer
                    open={this.state.changeLang}
                        onRequestClose={this.toggle}
                        modalElementClass="modalLang">
                        <div>                    
                        
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        
                        <div onClick={() => this.setLang('it')} style={{ padding: '20px', width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇮🇹 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>Italiano</p>
                        </div>

                        <div onClick={() => this.setLang('en')} style={{  width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇬🇧 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>English</p>
                        </div>

                        </div>

                        <div style={{ marginTop: '-35px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        
                        <div onClick={() => this.setLang('ja')} style={{ padding: '20px', width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇯🇵 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>日本</p>
                        </div>

                        <div onClick={() => this.setLang('es')} style={{  width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇪🇸 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>Español</p>
                        </div>

                        </div>

                    <div  style={{ marginTop: '-20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    <br />
                    </div>
                    </div>
            </Drawer>

      </div>
    )
  }
}

export default LangOption