import React, { Component } from 'react'
import logo from './getfy.svg';
import ThemeManager from './ThemeManager';
import MainInfo from './MainInfo';
import i18n from "i18next";

class Landing extends Component {

    constructor(props){
        super(props);
        var mainInfo = null;
        var info = props.info;
        if(info != null){
            mainInfo = [];
            mainInfo.eventDate = props.info.date;
            mainInfo.clubName = props.info.place;
            mainInfo.eventName = props.info.name;
        }

        this.state = {validity: props.validity, info: mainInfo};
        
    }


    render() {


        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            
            <div>
                <br />
                <br />
                {this.state.info!= null && 
                <div>
                <MainInfo info={this.state.info} />
                <div style={{ marginTop: '50px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>{i18n.t('cant.booking')}</div>
                <div style={{ marginTop: '5px', color: _secondaryTextColor, fontSize: '25px', fontWeight: 'bold'}}>{i18n.t('sold.out')}</div>
                </div>
                }   
                {this.state.info== null && 
                <div>
                <div style={{ marginTop: '50px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>{i18n.t('page.not.found')}</div>
                </div>
                }    
                <br />
                <br />
            </div>
        )
        
    }
}

export default Landing
