import React, { Component } from 'react'
import BookingSingleOptionElement from './BookingSingleOptionElement';
import BookingSingleOptionQuantity from './BookingSingleOptionQuantity';
import ThemeManager from './ThemeManager';

export class BookingSingleOption extends Component {

    constructor(props){
        super(props);

        var elements = [];
        var hourslot = [];
        var lastIndex = this.props.details.elements.length - 1;


        if(props.details.quantity==='1'){
            this.props.details.elements.map(function(item, i){
                
                var houritem = [];
                houritem.details = item;
                houritem.selected = false;
                hourslot.push(houritem);
                elements.push(hourslot);
                hourslot = [];
                
            });
        }else{
            this.props.details.elements.map(function(item, i){
                if(i%2===1){
                    var houritem = [];
                    houritem.details = item;
                    houritem.selected = false;
                    hourslot.push(houritem);
                    elements.push(hourslot);
                    hourslot = [];
                }else if(i === lastIndex){
                    var houritem = [];
                    houritem.details = item;
                    houritem.selected = false;
                    hourslot.push(houritem);
                    elements.push(hourslot);
                    hourslot = [];
                }else{
                    var houritem = [];
                    houritem.details = item;
                    houritem.selected = false;
                    hourslot.push(houritem);
                }
            });
        }

        

        var subTitle = this.subTitleString(props.details);

        var globalQuantity = 0;
        
        var maxQuantity = props.details.max_options;
        if(props.details.as_guest == '1'){
            maxQuantity = props.guests;
        }

  

        this.state = { 
            details: props.details,
            elements: elements,
            subTitle: subTitle,
            guests: props.guests,
            maxQuantity: maxQuantity,
            globalQuantity: globalQuantity
        };
    }


    static getDerivedStateFromProps(props, current_state) {
        if(current_state.guests !== props.guests){
            
        var elements = [];
        var hourslot = [];
        var lastIndex = props.details.elements.length - 1;

        if(props.details.quantity==='1'){
            props.details.elements.map(function(item, i){
                
                var houritem = [];
                houritem.details = item;
                houritem.selected = false;
                hourslot.push(houritem);
                elements.push(hourslot);
                hourslot = [];
                
            });
        }else{

        props.details.elements.map(function(item, i){
            if(i%2===1){
                var houritem = [];
                houritem.details = item;
                houritem.selected = false;
                hourslot.push(houritem);
                elements.push(hourslot);
                hourslot = [];
            }else if(i === lastIndex){
                var houritem = [];
                houritem.details = item;
                houritem.selected = false;
                hourslot.push(houritem);
                elements.push(hourslot);
                hourslot = [];
            }else{
                var houritem = [];
                houritem.details = item;
                houritem.selected = false;
                hourslot.push(houritem);
            }
        });
        }

        var globalQuantity = 0;
        
        var maxQuantity = props.details.max_options;
        if(props.details.as_guest == '1'){
            maxQuantity = props.guests;
        }
            
            return {
                details: props.details,
                elements: elements,
                guests: props.guests,
                maxQuantity: maxQuantity,
                globalQuantity: globalQuantity
              }
        }
        
        return null;
    }


    subTitleString = (info) => {

        if(info.quantity=='0'){
            return this.subStringNoQuantity(info);
        }else{
            return this.subStringQuantity(info);
        }
        
    }

    subStringNoQuantity = (info) => {

        if(info.max_options==info.min_options){
            if(info.max_options=='1'){
                return 'scelta obbligatoria';
            }
            return info.max_options+' scelte obbligatorie';
        }else if(info.min_options=='0'){
            if(info.max_options=='1'){
                return 'al massimo una scelta';
            }
            return 'al massimo '+info.max_options+' scelte';
        }else {
            if(info.min_options=='1'){
                return 'almeno una scelta, al massimo '+info.max_options;
            }
            return 'almeno '+info.min_options+' scelte, al massimo '+info.max_options;
        }

    }

    subStringQuantity = (info) => {


        if(info.as_guest=='1'){
            return 'in base agli ospiti';
        }

        if(info.max_options==info.min_options){
            if(info.max_options=='1'){
                return 'scelta obbligatoria';
            }
            return info.max_options+' scelte obbligatorie';
        }else if(info.min_options=='0'){
            if(info.max_options=='1'){
                return 'al massimo una scelta';
            }
            return 'al massimo '+info.max_options+' scelte';
        }else {
            if(info.min_options=='1'){
                return 'almeno una scelta, al massimo '+info.max_options;
            }
            return 'almeno '+info.min_options+' scelte, al massimo '+info.max_options;
        }

    }

    getNumberOfSelected = (elements) => {
        var tot = 0;
        for(var i=0; i<elements.length; i++){
            var thisSlot = elements[i];
            for(var j=0; j<thisSlot.length; j++){
                if(thisSlot[j].selected){
                    tot++;
                }
            }
        }

        return tot;
    }

    resetSelected = (elements) => {
        for(var i=0; i<elements.length; i++){
            var thisSlot = elements[i];
            for(var j=0; j<thisSlot.length; j++){
                thisSlot[j].selected = false;
                this.props.select(this.state.details.id, thisSlot[j].details.id, true);
            }
        }

        return elements;
    }

    selectElement = (slot,index,select) => {
        //solo per opzioni non quantitative
        var elements = this.state.elements;
        //reset se scelta unica
        if(select && parseInt(this.state.details.max_options)===this.getNumberOfSelected(elements)){
            elements = this.resetSelected(elements);
        }

        if(select){
            elements[slot][index].selected = true;
            this.props.select(this.state.details.id, elements[slot][index].details.id, false);
        }else{
            elements[slot][index].selected = false;
            this.props.select(this.state.details.id, elements[slot][index].details.id, true);
        }
        
        this.setState({ elements: elements});

    }

    selectElementQuantity = (slot,index,select) => {
        //solo per opzioni non quantitative
        var elements = this.state.elements;
        
        if(select){
            this.props.selectQuantity(this.state.details.id, elements[slot][index].details.id, true);
            this.setState({ globalQuantity: this.state.globalQuantity+1});
        }else{
            this.props.selectQuantity(this.state.details.id, elements[slot][index].details.id, false);
            this.setState({ globalQuantity: this.state.globalQuantity-1});
        }
        

    }


    incrementGlobalQuantity = () => {
       
    }

    decrementGlobalQuantity = () => {
        
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                <br />
                <div style={{ marginTop: '0px', color: _mainTextColor, fontSize: '15px', fontWeight: 'normal'}}>
                   {this.state.details.option_name}
                </div>
                <div style={{ marginTop: '5px', color: _secondaryTextColor, fontSize: '12px', fontWeight: 'normal'}}>
                  {this.state.subTitle}
                </div>

                {
           this.state.details.quantity == '0' && this.state.elements.map((hourSlot, i) =>
 
                    <div key={i}  style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>       
                
                   { hourSlot.map((houritem, k) =>
                                        
                        <BookingSingleOptionElement selected={houritem.selected} key={k + i*3} slot={i} index={k} selectThis={this.selectElement} details={houritem.details} />

                    )

                    }
                
                    </div>
                
                )

            
            }

{
           this.state.details.quantity != '0' && this.state.elements.map((hourSlot, i) =>
 
                    <div key={i}  style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>       
                
                   { hourSlot.map((houritem, k) =>
                   
                      
                        <BookingSingleOptionQuantity selectQuantity={this.selectElementQuantity} maxQuantity={this.state.maxQuantity} globalQuantity={this.state.globalQuantity} quantity={0} key={k + i*3} slot={i} index={k} details={houritem.details} />
                      

                    )

                    }
                
                    </div>
                
                )

            
            }
              
            </div>
        )
    }
}

export default BookingSingleOption
