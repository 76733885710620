import React, { Component } from 'react'
import RemoteBooking from './RemoteBooking';
import RemotePayment from './RemotePayment';
import BookingDetails from './BookingDetails';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class RemoteManager extends Component {

    constructor(props){
        super(props);
        var bookingInfo = props.booking;
        bookingInfo.token = props.user.token;
        this.state = { userInfo: props.user, bookingInfo: bookingInfo, isBooking: false, bookingerror: false};
        
      }

      

      confirmBook = (e) => {

        if(!this.state.isBooking){
            this.setState({ isBooking: true});
            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.userInfo.token
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/booking/confirm';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
                
                if(r==null){
                    window.location.reload();
                    
                }else{
                   //error
                    this.setState({ bookingerror: true});
                }
                
         
              }).catch(e => {
                  
                    this.setState({ bookingerror: true});
              });
        }

    }

    cancelBook = (e) => {

        if(!this.state.isBooking){
            this.setState({ isBooking: true});
            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.userInfo.token
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/booking/decline';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
                
                window.location.reload();
                
         
              }).catch(e => {
                
                window.location.reload();
              });
        }

    }


    cancelBookPayment = (e) => {

        if(!this.state.isBooking){
            this.setState({ isBooking: true});
            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.userInfo.token
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/payment/decline';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
   
                    window.location.reload();

              }).catch(e => {
               
                window.location.reload();
              });
        }

    }

    goBack = (e) => {
        this.props.goMain();
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
               
               

                { this.state.bookingInfo.user_status === "0" &&
                    <div>
                        <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
                         <div style={{ marginTop: '-10px', color: _secondaryTextColor, fontSize: '14px', fontWeight: 'normal'}}>{i18n.t('confirm.your.booking')}</div>
                        <RemoteBooking booking={this.state.bookingInfo} confirmBook={this.confirmBook} cancelBook={this.cancelBook} error={this.state.bookingerror} />
                    </div>
                }

                { (this.state.bookingInfo.user_status === "1" && this.state.bookingInfo.paid === "0" && this.state.bookingInfo.payment !== "0") &&
                    <div>
                        <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
                         <div style={{ marginTop: '10px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                         {i18n.t('is.required')} <p style={{ marginLeft:'8px', marginRight:'8px', color: _selectionColor, fontWeight: 'bold', fontSize: '14px'}}>{i18n.t('payment')} </p> {i18n.t('deposit')} 
                        </div>
                        <div style={{ marginTop: '-10px', color: _secondaryTextColor, fontSize: '14px', fontWeight: 'normal', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {i18n.t('to.confirm.booking')}
                        </div>
                        {
                    (this.state.bookingInfo.payment_text !== '') ? (
                        <p style={{ marginTop: '25px', marginLeft:'10px', marginRight:'4px', color: _secondaryTextColor, fontWeight: 'bold', fontSize: '12px'}}> 
                        {i18n.t('business.notes')}<br />{this.state.bookingInfo.payment_text }
                        </p>
                    )
                    : (
                        null
                    )

                }   
                        <RemotePayment user={this.state.userInfo} cancelBook={this.cancelBookPayment} booking={this.state.bookingInfo} />
                    </div>
                }  

               

                 {
                   (this.state.bookingInfo.user_status === "1" && (this.state.bookingInfo.paid === "1" || this.state.bookingInfo.paid === "0" && this.state.bookingInfo.payment === "0"))
                   ? (
                    
                    <div>
                    
                    <BookingDetails  user={this.state.userInfo} eventinfo={this.state.bookingInfo} back={this.goBack} info={this.state.bookingInfo} backOption="indietro" />
                    </div>
                   )
                   : (
                       null
                   )
                }         
                

            </div>
        )
    }
}

export default RemoteManager
