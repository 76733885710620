import React, { Component } from 'react'
import BookingDate from './BookingDate';
import logo from './getfy.svg';
import NormalBooking from './NormalBooking';
import UserBooking from './UserBooking';
import EventsList from './EventsList';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class DataManager extends Component {

    constructor(props){
        super(props);

        var newBooking = true;

        if(props.booking!==null){
          newBooking = false;
        }


        this.state = { 
          eventInfo: props.info, 
          bookingInfo: props.booking, 
          userInfo: props.user,
          selectedDate: '', 
          normalBooking: null, 
          newBooking: newBooking, 
          showMyBooking: false, 
          existsBooking: null,
          eventsList: [],
          eventDate: ''
        };
      }


      getDateInfo = (date) => {

        var textualDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }).format(date);
          
        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
            sessiontoken = '';
        }
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                date: textualDate,
                token: sessiontoken,
                bookingtoken: this.state.userInfo.token
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v7/booking/date';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
   
      var generalInfo = r.info;
      if(generalInfo.type=='event'){
       
        if(generalInfo.details.length===1){
         
          var newLocation = "/"+generalInfo.details[0].code;
          window.location.href = newLocation;
          return;
        }
        
        this.setState({ eventsList: generalInfo.details, eventDate: date, newBooking: true });

      }else if(generalInfo.type=='remote'){
        var newLocation = "/"+generalInfo.details.code;
        window.location.href = newLocation;
        return;
      }else if(generalInfo.type=='normal'){
          
          var normalBooking = generalInfo.details;
         
          var existsBooking = generalInfo.booking;
          if(normalBooking==null && existsBooking==null){
            window.location.reload();
          }
          
          this.setState({ normalBooking: normalBooking, existsBooking: existsBooking });

      }else{
          window.location.reload();
        }
      
      
    }).catch(e => {
        window.location.reload();
    });


      }


      showBookingByDate = (date) => {


        this.getDateInfo(new Date(date));

      }

      showBookingCalendar = (e) => {
        this.setState({ newBooking: true, showMyBooking: true});
      }

      showBooking = (e) => {
        this.setState({ newBooking: false});
      }

      cancelBooking = (e) => {
        window.location.reload();
      }


    
    render() {

      let themeManager = ThemeManager.getInstance();
      const _backgroundColor = themeManager.getBackgroundColor();
      const _selectionColor = themeManager.getSelectionColor();
      const _mainTextColor = themeManager.getMainTextColor();
      const _secondaryTextColor = themeManager.getSecondaryTextColor();
      const _itemBackgroundColor = themeManager.getItemBackgroundColor();
      const _errorColor = themeManager.getErrorColor();

        return (
            <div>

              
                {
                   !this.state.newBooking && this.state.normalBooking === null && this.state.bookingInfo!==null &&this.state.eventInfo.dates[0]!=null
                   ? (
                    <UserBooking bookNow={this.showBookingCalendar} showBooking={this.showBookingByDate} user={this.state.userInfo} booking={this.state.bookingInfo} />
                   )
                   : (
                       null
                   )
                }
                
                {
                   this.state.newBooking && this.state.normalBooking === null && this.state.eventInfo.dates[0]!=null && this.state.eventsList.length==0
                   ? (
                    <BookingDate  user={this.state.userInfo} showBooking={this.showBooking} onGoingEvents={this.state.eventInfo.ongoing_events} selectDate={this.getDateInfo} dates={this.state.eventInfo.dates} showCancel={this.state.showMyBooking} />
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.newBooking && this.state.normalBooking === null && this.state.eventInfo.dates[0]!=null && this.state.eventsList.length>0
                   ? (
                    <EventsList events={this.state.eventsList} date={this.state.eventDate} />
                   )
                   : (
                       null
                   )
                }


                {
                   this.state.normalBooking !== null 
                   ? (
                    <NormalBooking showBooking={this.showBookingByDate} existsBooking={this.state.existsBooking} info={this.state.normalBooking} user={this.state.userInfo} bookNow={this.cancelBooking}  />
                   )
                   : (
                       null
                   )
                }


                {
                   this.state.normalBooking === null  && this.state.eventInfo.dates[0]==null
                   ? (
                    <div>
                    <div style={{marginTop: '50px', color: _mainTextColor }}>{i18n.t('cant.booking')}</div>
                  </div>
                   )
                   : (
                       null
                   )
                }


            </div>
        )
    }
}

export default DataManager
