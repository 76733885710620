import React, { Component  } from 'react'
import BookingNew from './BookingNew';
import BookingRecap from './BookingRecap';

export class BookingTable extends Component {

  

    constructor(props){
        super(props);
        var bookRecap = [];
        bookRecap.guests = 0;
        bookRecap.hour = '';
        bookRecap.comments = '';

        var maxGuests = 50;
        if(props.event.max_guests !== 'undefined'){
            maxGuests = props.event.max_guests;
        }

        this.state = { showBookForm: true, maxGuests: maxGuests, showRecapForm: false, bookRecap: bookRecap, userInfo: props.user, eventInfo: props.event, isBooking: false, bookingerror: false, canCancel: props.canCancel, normal: props.normal};
       
    }


    bookNow = (guests, hour, comments, options, optionsArray) => {

        var bookRecap = this.state.bookRecap;
        bookRecap.guests = guests;
        bookRecap.hour = hour;
        bookRecap.comments = comments;
        bookRecap.selectedOptions = options;
        bookRecap.options = optionsArray;

        this.setState({ showBookForm: false, showRecapForm: true, bookRecap: bookRecap });
    }

    confirmBook = () => {
       
        if(!this.state.isBooking){
            this.setState({ isBooking: true});
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.userInfo.token,
                    guests: this.state.bookRecap.guests,
                    hour: this.state.bookRecap.hour,
                    comments: this.state.bookRecap.comments,
                    options: this.state.bookRecap.selectedOptions
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/user/v7/booking/table';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                if(r==null){
                    if(this.state.normal){
                        this.props.bookAction();
                    }else{
                        window.location.reload();
                    }
                    
                }else{
                   //error
                    this.setState({ bookingerror: true});
                }
                
         
              }).catch(e => {
                  
                    this.setState({ bookingerror: true});
              });
        }
        

    }

    cancel = () => {
        this.setState({ showBookForm: true, showRecapForm: false});
    }


    cancelBooking = () => {
        this.props.cancel();
    }

    render() {
        return (
            <div>
                
               
                
               
                { this.state.showBookForm && <BookingNew maxGuests={this.state.maxGuests} user={this.state.userInfo} cancel={this.cancelBooking} info={this.state.eventInfo} bookNow={this.bookNow} canCancel={this.state.canCancel} /> }

                { this.state.showRecapForm && <BookingRecap eventinfo={this.state.eventInfo} cancel={this.cancel} error={this.state.bookingerror} confirmBook={this.confirmBook} info={this.state.bookRecap} /> }

                

           
                <div style={{ height: '50px'}}></div>
                   
            </div>
        )
    }
}

export default BookingTable
