import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class OngoingEvents extends Component {


    constructor(props){
        super(props);

        this.state = { 
          events: props.events
        };

       
      }


      goToEvent = (event_code) => {

        var newLocation = "/"+event_code;
        window.location.href = newLocation;
        return;
      }

  render() {


    let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _errorColor = themeManager.getErrorColor();

    return (
      <div>

      { this.state.events.length > 0 &&    
        <div> 
        <div style={{ marginTop: '10px', color: _secondaryTextColor, fontSize: '12px', fontWeight: 'bold'}}> {i18n.t('ongoing.events')}</div>
            
         
                 <div style={{ marginTop: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                {
           this.state.events.map((event, i) =>
            <div style={{ marginTop: '10px' }} key={i}>
           
           <div style={{width: '250px', backgroundColor: _itemBackgroundColor, minHeight: '50px', borderRadius: '5px',  color: _secondaryTextColor, textAlign: 'center', 
              justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
              onClick={() => this.goToEvent(event.code)} >

              <div style={{  marginLeft: '15px', width: '30px', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
              <p style={{  fontSize: '20px'}}>🥳</p>
              </div>

              <div style={{ width: '200px', textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
              <p style={{color: _mainTextColor, fontWeight: 'bold'}}>{event.Name}</p>
              
              { event.booking_one === '1' &&    
              <p style={{marginTop: '-10px'}}> {i18n.t('single.entry')} </p>
              }
              { event.booking_one === '0' &&    
              <p style={{marginTop: '-10px'}}> {i18n.t('multiple.entry')} </p>
              }
              </div>
            
              </div>
              </div>
                )
                }
              
              
                
                </div>  
        </div>

        </div> 
                 
      }

      </div>
    )
  }
}

/*
 <div style={{width: '250px', backgroundColor: _itemBackgroundColor, minHeight: '60px', borderRadius: '5px',  color: _secondaryTextColor, textAlign: 'center', 
              justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
              onClick={this.onItemClick} >

              <div style={{ backgroundColor: 'red', marginLeft: '15px', width: '30px'}}>
              <p style={{fontSize: '20px'}}>🥳</p>
              </div>

              <div style={{ width: '200px', textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
              <p style={{color: _mainTextColor, fontWeight: 'bold'}}>{event.Name}</p>
              <p style={{marginTop: '-10px'}}> {event.booking_one} </p>
              </div>
              */

export default OngoingEvents