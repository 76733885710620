import React, { Component } from 'react'
import BookingDetails from './BookingDetails';
import BookingSingle from './BookingSingle';
import BookingSinglePayment from './BookingSinglePayment';
import BookingTable from './BookingTable';
import InvalidEvent from './InvalidEvent';
import SingleBookingDetails from './SingleBookingDetails';

export class Event extends Component {

    constructor(props){
        super(props);
       
        this.state = { eventInfo: props.info, userInfo: props.user, bookingInfo: props.booking, validity: 0};
        
    }

  
    static getDerivedStateFromProps(props, current_state) {
        if(current_state.userInfo !== props.user){
            if (current_state.eventInfo !== props.info) {
            
                return {
                 eventInfo: props.info, 
                 userInfo: props.user       
                }
            }
            return {
                userInfo: props.user       
              }
        }else{
            if (current_state.eventInfo !== props.info) {          
                return {
                 eventInfo: props.info          
                }
            }
        }
        
        return null;
    }

    goBack = (e) => {
        this.props.goToMainPage();
    }

    render() {
        return (
            <div style={{marginTop: '50px'}}>
      

                {     
                    this.state.userInfo !== null && this.state.bookingInfo !== null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' &&  this.state.bookingInfo.type === 't'
                    ? (
                        <BookingDetails user={this.state.userInfo} eventinfo={this.state.eventInfo} back={this.goBack} info={this.state.bookingInfo}  backOption="indietro" />
                    )
                    : (
                        null
                    )

                }

                {
                    this.state.userInfo !== null && this.state.bookingInfo !== null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' &&  this.state.bookingInfo.type === 's'
                    ? (
                        <SingleBookingDetails user={this.state.userInfo} back={this.goBack} info={this.state.bookingInfo}  backOption="indietro" />
                    )
                    : (
                        null
                    )

                }


                { this.state.userInfo !== null && this.state.bookingInfo === null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' && this.state.validity!==0
                    ? (
                        <InvalidEvent validity={this.state.validity} />
                    )
                    : (
                        null
                    )
                }



                {
                    this.state.userInfo !== null && this.state.bookingInfo === null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' && this.state.validity===0 && this.state.eventInfo.bookingtype === 'table'
                    ? (
                        <BookingTable normal={false} user={this.state.userInfo} event={this.state.eventInfo} />
                    )
                    : (
                        null
                    )
                }

                {
                    this.state.userInfo !== null && this.state.bookingInfo === null && (this.state.eventInfo.payment==="0" || this.state.eventInfo.payment===null || this.state.eventInfo.payment===undefined) && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' && this.state.validity===0 && this.state.eventInfo.bookingtype === 'single'
                    ? (
                       <BookingSingle user={this.state.userInfo} event={this.state.eventInfo} />
                    )
                    : (
                        null
                    )
                }

                {
                    this.state.userInfo !== null && this.state.bookingInfo === null && (this.state.eventInfo.payment!=="0" && this.state.eventInfo.payment!==null && this.state.eventInfo.payment!==undefined) && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' && this.state.validity===0 && this.state.eventInfo.bookingtype === 'single'
                    ? (
                        <BookingSinglePayment user={this.state.userInfo} event={this.state.eventInfo} />
                    )
                    : (
                        null
                    )
                }



            </div>
        )
    }
}

export default Event
