import React, { Component } from 'react'
import BookingSingleOption from './BookingSingleOption';

export class BookingOptions extends Component {


    constructor(props){

        super(props);
        
        var options = props.options;
       
        for(var i=0; i<options.length; i++){
            options[i].selection = [];
        }



        this.state = { 
            options: options,
            guests: props.guests,
            updateStatus: true
        };



        this.optionStatus();
    }


    componentDidUpdate() {
        if(this.state.updateStatus){
            this.setState({ updateStatus: false});
            this.optionStatus();
        }
       
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.guests !== props.guests){
            var options = props.options;

            for(var i=0; i<options.length; i++){
                options[i].selection = [];
            }
            return {
                guests: props.guests,
                options: options,
                updateStatus: true
              }
        }
        
        return null;
    }


    selectOption = (id, option_id, remove) => {

        var optionIndex = this.getOptionIndexByID(id);
        var options = this.state.options;
        
        if(optionIndex!==-1){
            var optionSelections = options[optionIndex].selection;
            
            if(remove){
                for(var i=0; i<optionSelections.length; i++){
                    if(optionSelections[i].id==option_id){
                        optionSelections.splice(i,1);
                    }
                }
            }else{
                var thisOption = [];
                thisOption.id = option_id;
                optionSelections.push(thisOption);
            }

            options[optionIndex].selection = optionSelections;
            this.setState({ options: options });

        }
        
        this.optionStatus();
    }


    selectOptionQuantity = (id, option_id, add) => {

        var optionIndex = this.getOptionIndexByID(id);
        var options = this.state.options;
      
        if(optionIndex!==-1){
            var optionSelections = options[optionIndex].selection;
            
            if(!add){
                for(var i=0; i<optionSelections.length; i++){
                    if(optionSelections[i].id==option_id){
                        if(optionSelections[i].quantity=="1"){
                            optionSelections.splice(i,1);
                        }else{
                            optionSelections[i].quantity = optionSelections[i].quantity-1;
                        }                      
                    }
                }
            }else{
                var find = false;

                for(var i=0; i<optionSelections.length; i++){
                    if(optionSelections[i].id==option_id){
                        find = true;
                        optionSelections[i].quantity = optionSelections[i].quantity+1;                                            
                    }
                }

                if(!find){
                    var thisOption = [];
                    thisOption.id = option_id;
                    thisOption.quantity = 1;
                    optionSelections.push(thisOption);
                }
               
            }
            
            options[optionIndex].selection = optionSelections;
            this.setState({ options: options });

        }
        
        this.optionStatus();
    }


    getOptionIndexByID = (id) => {
        for(var i=0; i<this.state.options.length; i++){
            if(this.state.options[i].id===id){
                return i;
            }
        }

        return -1;
    }

    optionStatus = () => {

        for(var i=0; i<this.state.options.length; i++){
            if(!this.checkOptionConstraint(this.state.options[i])){
                this.props.select(this.getOptionInfoToSend(), this.getOptionInfoRecap());
                this.props.status(false);
                return;
            }
        }

        this.props.select(this.getOptionInfoToSend(), this.getOptionInfoRecap());
        this.props.status(true);
    }

    getOptionInfoToSend = () =>{

        var optionArray = '';

        for(var i=0; i<this.state.options.length; i++){
            var element_id = this.state.options[i].id;
            var element_quantity = this.state.options[i].quantity;
            var element_selection = this.state.options[i].selection;  
            var thisElement = '[';
            thisElement += element_id;
            thisElement += ']';

            thisElement += '[';
            thisElement += element_quantity;
            thisElement += ']';

            var thisElementSelection = '';

            if(element_quantity=='0'){             
                for(var j=0; j<element_selection.length; j++){
                    thisElementSelection += '(';
                    thisElementSelection += element_selection[j].id;
                    thisElementSelection += ')';
                }
            }else{             
                for(var j=0; j<element_selection.length; j++){
                    thisElementSelection += '(';
                    thisElementSelection += element_selection[j].id;
                    thisElementSelection += '-';
                    thisElementSelection += element_selection[j].quantity;
                    thisElementSelection += ')';
                }
            }

            thisElement += '[';
            thisElement += thisElementSelection;
            thisElement += ']';

            if(thisElementSelection!=''){
                if(optionArray==''){
                    optionArray += thisElement;
                }else{
                    optionArray += '/';
                    optionArray += thisElement;
                }             
            }
 
        }

        return optionArray;
    }

    getOptionInfoRecap = () =>{
      
        var optionArray = [];

        for(var i=0; i<this.state.options.length; i++){
            var thisOption = [];
            var element_quantity = this.state.options[i].quantity;
            var element_selection = this.state.options[i].selection;  
            var all_elements = this.state.options[i].elements;  

            thisOption.option_name = this.state.options[i].option_name;
            thisOption.option_id = this.state.options[i].id;

           

            var thisElementSelections = [];

            if(element_quantity=='0'){             
                for(var j=0; j<element_selection.length; j++){
                    var thisElement = [];
                    thisElement.element_name = this.getElementNameByID(all_elements, element_selection[j].id);
                    thisElement.external_url = this.getElementUrlByID(all_elements, element_selection[j].id);
                    thisElement.quantity = '';
                    thisElementSelections.push(thisElement);
                }
            }else{             
                for(var j=0; j<element_selection.length; j++){
                    var thisElement = [];
                    thisElement.element_name = this.getElementNameByID(all_elements, element_selection[j].id);
                    thisElement.external_url = this.getElementUrlByID(all_elements, element_selection[j].id);
                    thisElement.quantity = element_selection[j].quantity;
                    thisElementSelections.push(thisElement);
                }
            }

            

            if(thisElementSelections.length>0){
                thisOption.elements = thisElementSelections;
                optionArray.push(thisOption);
            }
 
        }

        return optionArray;
    }


    getElementNameByID = (elements, id) => {
        for(var i=0; i<elements.length; i++){
            if(elements[i].id == id){
                return elements[i].element_name;
            }
        }
        return '';
    }

    getElementUrlByID = (elements, id) => {
        for(var i=0; i<elements.length; i++){
            if(elements[i].id == id){
                return elements[i].external_url;
            }
        }
        return '';
    }

    checkOptionConstraint = (option) => {

        if(option.quantity=='0'){
            return this.checkNormalOptionConstraint(option);
        }else{
            return this.checkQuantityOptionConstraint(option);
        }

        return false;
    }

    checkQuantityOptionConstraint = (option) => {

        if(this.checkGuestConditionConstraint(option, this.state.guests)){

            if(option.as_guest=='1'){
                return this.checkAsGuestCondition(option);
            }

            var totalSelcted = this.calculatedQuantitySelectedOptions(option);

            if(totalSelcted>=option.min_options && totalSelcted<=option.max_options){
                return true;
            }

            return false;
        }

        return true;
    }

    checkAsGuestCondition = (option) => {

        if(this.calculatedQuantitySelectedOptions(option)===this.state.guests){
            return true;
        }

        return false;
    }


    calculatedQuantitySelectedOptions = (option) => {

        var totalSelected = 0;

        for(var i=0; i<option.selection.length; i++){
               totalSelected += option.selection[i].quantity;                                            
        }
        return totalSelected;
    }

    checkNormalOptionConstraint = (option) => {

        if(this.checkGuestConditionConstraint(option, this.state.guests) && !(option.selection.length>=option.min_options && option.selection.length<=option.max_options)){
            return false;
        }

        return true;
    }

    checkGuestConditionConstraint = (option, guests) => {
        //l'opzione è selezionabile se il numero di ospiti attuali è compreso tra la soglia minima e la massima
        if(guests>=option.min_guest && guests<=option.max_guest){
            return true;
        }

        return false;

    }

    render() {
        return (
            <div>
             

            { this.state.options.map((option, i) =>
            (this.checkGuestConditionConstraint(option, this.state.guests) && 
             <BookingSingleOption key={i} guests={this.state.guests} select={this.selectOption} selectQuantity={this.selectOptionQuantity} details={option} />            
            ))
            }

                
            </div>
        )
    }
}

export default BookingOptions

/*

   {
                    this.checkGuestConditionConstraint(this.state.options[0], this.state.guests)
                   ? (
                        <BookingSingleOption guests={this.state.guests} select={this.selectOption} selectQuantity={this.selectOptionQuantity} details={this.state.options[0]} />
                   )
                   : (
                     null
                   )
                }



                { true &&  <BookingSingleOption key={i} guests={this.state.guests} select={this.selectOption} selectQuantity={this.selectOptionQuantity} details={option} />
            
            }
            
                */