import React, { Component } from 'react'
import '../App.css';
import ThemeManager from '../ThemeManager';
import i18n from "i18next";

export class Register extends Component {

    constructor(props){
        super(props);
     
        this.state = { nameValue: '', surnameValue: '', error: false, ck1: false, ck2: false, animationShake: '', animationShake2: ''};    
    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.error !== props.error) {
            return {
             error: props.error          
            }
        }
        return null;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    insertData = (e) => {
        var insert = e.target.value;
        insert =  this.capitalizeFirstLetter(insert);
        this.setState({ [e.target.name] : insert});
    }


    confirmRegister = (e) => {
        if(this.state.ck1 && this.state.ck2 && this.state.nameValue.length>0 && this.state.surnameValue.length>0){
            this.props.register(this.state.nameValue,this.state.surnameValue);
        }else if(!this.state.ck1){
            this.setState({ animationShake: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }else if(!this.state.ck2){
            this.setState({ animationShake2: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: '', animationShake2: ''}); 
        clearInterval(this.intervalID);
    }

    checkboxAction = (e) => {
        var checkBox = e.target.name;
        if(checkBox === "ck1"){
            if(this.state.ck1){
                this.setState({ ck1: false}); 
            }else{
                this.setState({ ck1: true}); 
            }
        }else if(checkBox === "ck2"){
            if(this.state.ck2){
                this.setState({ ck2: false}); 
            }else{
                this.setState({ ck2: true}); 
            }
        }
        
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
             

            <div style={{marginTop: '60px'}}>
            <p style={{color: _secondaryTextColor, fontSize: '14px'}}>{i18n.t('insert.name')}</p>
            <p style={{marginTop: '-12px', color: _secondaryTextColor, fontSize: '14px'}}>{i18n.t('to.continue.register')}</p>
            </div>
                
                
            { this.state.error && <p style={{ fontSize: '14px', color: _errorColor }}>{i18n.t('request.error.reload')}</p> }
            <div style={{marginTop: '25px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="nameValue"
                value={this.state.nameValue}
                placeholder={i18n.t('name')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor}}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="surnameValue"
                value={this.state.surnameValue}
                placeholder={i18n.t('surname')}
                onChange={this.insertData}
                />
                </div>
                
            </div>


            <div style={{marginTop: '25px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
            <div style={{marginLeft: '25px',  height: '35px', borderRadius: '8px', width: '250px', color: _mainTextColor, fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.getfy.it/agreements/terms-and-conditions.pdf">
                 {i18n.t('accept.terms')}
                 </a>
                
             </div>

             
             
         </div>

         <div style={{marginTop: '0px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
           

             <div style={{marginLeft: '25px',  height: '35px', borderRadius: '8px', width: '250px', color: _mainTextColor, fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.getfy.it/agreements/privacy-policy">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
             
         </div>

            
                


                <div  style={{marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmRegister}
            >
                    {i18n.t('confirm')}</p>
                    </div>


                    
              
            </div>
        )
    }
}

export default Register
