import React, { Component } from 'react'
import BirthDate from './BirthDate';
import Sex from './Sex';
import Email from './Email';

export class PersonalData extends Component {


    constructor(props){
        super(props);

        this.state = { userInfo: props.user};
       
    }


    render() {
        return (
            <div>
             
             {
                this.state.userInfo.info.email == ''
            ? (
                    <Email user={this.state.userInfo} />
                )
            : (
                 null
            )
            }

{
                this.state.userInfo.birth !== 'ok' && this.state.userInfo.info.email !== ''
            ? (
                    <BirthDate user={this.state.userInfo} />
                )
            : (
                 null
            )
            }

            {
                this.state.userInfo.sex !== 'ok' && this.state.userInfo.birth === 'ok' && this.state.userInfo.info.email !== ''
            ? (
                    <Sex user={this.state.userInfo} />
                )
            : (
                 null
            )
            }
                  
              
            </div>
        )
    }
}

export default PersonalData
