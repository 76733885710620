import React, { Component } from 'react'
import Event from './Event';
import MainInfo from './MainInfo';
import UserManager from './UserManager';
import DataManager from './DateManager';
import RemoteManager from './RemoteManager';

export class BookingManager extends Component {

    constructor(props){
        super(props);
        
        var generalInfo = props.info;
        
        var codeInfo = generalInfo.type;
        var mainCode = generalInfo.maincode;
        
        var eventInfo = [];
        eventInfo.eventDate = generalInfo.details.date;
        eventInfo.eventDateArray = generalInfo.details.dateArray;
        eventInfo.clubName = generalInfo.details.place;
        eventInfo.eventName = generalInfo.details.name;
        eventInfo.hours = generalInfo.details.hours;
        eventInfo.dates = generalInfo.details.dates;
        eventInfo.ongoing_events = generalInfo.details.ongoing_events;
        eventInfo.bookingtype = generalInfo.details.bookingtype;
        eventInfo.payment = generalInfo.details.payment;
        eventInfo.options = generalInfo.details.options;
        eventInfo.max_guests = generalInfo.details.max_guests;
        eventInfo.image = generalInfo.details.image;
        eventInfo.comments_text = generalInfo.details.comments_text;
        eventInfo.hide_covid = generalInfo.details.hide_covid;
        
        var bookingInfo = null;
        if(props.booking!=null && (codeInfo==='event' || codeInfo==='remote')){
            bookingInfo = [];
            bookingInfo.guests = props.booking.guests;
            bookingInfo.type = props.booking.type;
            bookingInfo.hour = props.booking.hour;
            bookingInfo.code = props.booking.code;
            bookingInfo.status = props.booking.status;
            bookingInfo.comments = props.booking.comments;
            bookingInfo.token = props.user.token;
            bookingInfo.user_status = props.booking.user_status;
            bookingInfo.payment = props.booking.payment;
            bookingInfo.paid = props.booking.paid;
            bookingInfo.stripe_proforma = props.booking.stripe_proforma;
            bookingInfo.payment_text = props.booking.payment_text;
            bookingInfo.options = props.booking.options;
            bookingInfo.comments_text = props.booking.comments_text;
            bookingInfo.hide_covid = props.booking.hide_covid;
        }else if(props.booking!=null && codeInfo==='normal'){
            bookingInfo = props.booking;
        }else{
            bookingInfo = props.booking;
        }

        
    
        this.state = { userInfo: props.user, bookingInfo: bookingInfo, eventInfo: eventInfo, type: codeInfo, mainCode: mainCode};
        
      }

      goMain = (e) => {
        var newLocation = "/"+this.state.mainCode;
        window.location.href = newLocation;
      }

    render() {

        
        return (
            <div>


                <MainInfo info={this.state.eventInfo} />

                
                {
                   this.state.type === "event" && this.state.userInfo !== null && this.state.userInfo.birth === 'ok' &&  this.state.userInfo.info.email !== '' && this.state.userInfo.sex === 'ok'
                   ? (
                    <Event goToMainPage={this.goMain} user={this.state.userInfo} booking={this.state.bookingInfo} info={this.state.eventInfo} />
                   )
                   : (
                       null
                   )
                }


                {
                   this.state.type === "normal" && this.state.userInfo !== null && this.state.userInfo.birth === 'ok' &&  this.state.userInfo.info.email !== '' && this.state.userInfo.sex === 'ok'
                   ? (
                    <DataManager user={this.state.userInfo} booking={this.state.bookingInfo} info={this.state.eventInfo} />
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.type === "remote" && this.state.userInfo !== null && this.state.userInfo.birth === 'ok' &&  this.state.userInfo.info.email !== '' && this.state.userInfo.sex === 'ok' && this.state.userInfo.phone === null
                   ? (
                    <RemoteManager goMain={this.goMain} user={this.state.userInfo} booking={this.state.bookingInfo} />
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.userInfo === null || (this.state.userInfo !== null && (this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok' || this.state.userInfo.info.email == '' || this.state.userInfo.phone !== null ))
                   ? (
                    <UserManager user={this.state.userInfo} /> 
                   )
                   : (
                       null
                   )
               }
         
            </div>
        )
    }
}

export default BookingManager
