import React, { Component } from 'react'
import Login from './Login/Login';
import PersonalData from './PersonalInfo/PersonalData';

export class UserManager extends Component {

    constructor(props){
        super(props);
        
        this.state = { userInfo: props.user };
        
    }


    render() {
        return (
            <div>
                
                { this.state.userInfo === null && <Login /> }
               
                {
                   this.state.userInfo !== null && (this.state.userInfo.phone===null && this.state.userInfo.prefix===null) && (this.state.userInfo.birth !== 'ok' || this.state.userInfo.info.email == '' || this.state.userInfo.sex !== 'ok' )
                   ? (
                       <PersonalData user={this.state.userInfo} /> 
                   )
                   : (
                       null
                   )
               }

                {
                   this.state.userInfo !== null && (this.state.userInfo.phone!==null && this.state.userInfo.prefix!==null)
                   ? (
                    <Login phone={this.state.userInfo.phone} prefix={this.state.userInfo.prefix} />
                   )
                   : (
                       null
                   )
               }

            </div>
        )
    }
}

export default UserManager
