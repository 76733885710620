import React, { Component } from 'react'
import BookingDetailsMini from './BookingDetailsMini';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class UserBooking extends Component {

    constructor(props){

        super(props);
        
        this.state = { bookingInfo: props.booking, userInfo: props.user };

    }

    selectBooking = (date) => {

       this.props.showBooking(date);

    }

    newBooking = (e) => {

        this.props.bookNow();
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
               
               <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>

                <div style={{ marginTop: '-10px', color: _secondaryTextColor, fontSize: '14px', fontWeight: 'normal'}}>{i18n.t('here.your.bookings')}</div>


                <div style={{ marginTop: '20px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                {
           this.state.bookingInfo.map((booking, i) =>
                    <BookingDetailsMini key={i} selectBooking={this.selectBooking} info={booking} />
                )
                }
              
                
                </div>  
                 </div>
               

                 <div  style={{marginTop: '25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.newBooking}
            >
                    {i18n.t('booking.new')} </p>
                    </div>
                    <br />
                    <br />
            </div>
        )
    }
}

export default UserBooking
