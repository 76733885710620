export default class ThemeManager {

    static myInstance = null;


    _backgroundColor = "#000000";
    _selectionColor = "#FFC200";
    _mainTextColor = "#FFFFFF";
    _secondaryTextColor = "#FFFFFFD9";
    _buttonTextColor = "#FFFFFF";
    _itemBackgroundColor = "#1E1E1E";
    _errorColor = '#FF0000'


    /**
     * @returns {ThemeManager}
     */
    static getInstance() {
        if (ThemeManager.myInstance == null) {
            ThemeManager.myInstance = new ThemeManager();
        }

        return this.myInstance;
    }

    getBackgroundColor() {
        return this._backgroundColor;
    }

    getSelectionColor() {
        return this._selectionColor;
    }

    getMainTextColor() {
        return this._mainTextColor;
    }

    getSecondaryTextColor() {
        return this._secondaryTextColor;
    }

    getButtonTextColor() {
        return this._buttonTextColor;
    }

    getItemBackgroundColor() {
        return this._itemBackgroundColor;
    }

    getErrorColor() {
        return this._errorColor;
    }



    setBackgroundColor(color) {
        this._backgroundColor = color;
    }

    setSelectionColor(color) {
        this._selectionColor = color;
    }

    setMainTextColor(color) {
        this._mainTextColor = color;
    }

    setSecondaryTextColor(color) {
        this._secondaryTextColor = color;
    }

    setButtonTextColor(color) {
        this._buttonTextColor = color;
    }

    setItemBackgroundColor(color) {
        this._itemBackgroundColor = color;
    }

    setErrorColor(color) {
        this._errorColor = color;
    }
}