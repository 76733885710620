import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingDetailsOptions extends Component {

    constructor(props){
        super(props);
        
        this.state = { options: props.options, external_url: '', openOptionInfo: false };
        
    }


    closeInfo = () => {
        this.setState({ openOptionInfo: false })
    }

    openInfo = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        //this.setState({ openOptionInfo: true, external_url: url })
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                <br />
                
                { 
           this.state.options.map((mainOption, i) =>
 
           <div key={i} style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                    <div>       
                     <div style={{ marginTop: '0px', color: _mainTextColor, fontSize: '15px', fontWeight: 'normal'}}>
                     {mainOption.option_name} 
                    </div>
                   { mainOption.elements.map((elementOption, k) =>
                                        
                        <div style={{ color: _mainTextColor, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} slot={i}  key={k + i*3} >

                        <div style={{ marginTop: '10px', width: '250px', minHeight: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                >
                    <div style={{ marginLeft: '0px', padding: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>

                    
                    
                    <div style={{ width: '100%', textAlign: 'left' }}>
                       

                        {
                        elementOption.quantity == ""
                    ? (
                        <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '100%', textAlign: 'center'}}> {elementOption.element_name}</div>
                    )
                    : (
                        <div style={{ color: _mainTextColor, marginLeft: '5px', fontSize: '14px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> {elementOption.element_name}</div>
                    )
                    }

                        { elementOption.external_url != '' &&
                        <div onClick={() => this.openInfo(elementOption.external_url)}  style={{ marginLeft: '5px', color: _selectionColor, fontSize: '10px', cursor: 'pointer', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> {i18n.t('show.more')}</div>
                        
                  
                        }
                        </div>

                    {
                        elementOption.quantity == ""
                    ? (
                        null
                    )
                    : (
                        <div style={{ marginLeft: '50px', marginRight: '20px', color: _mainTextColor, fontSize: '20px'}}>{elementOption.quantity}</div>
                    )
                    }

                   
                    </div>

                   

                    </div>

                    </div>
                    )

                    }
                
                </div>
                </div>
                
                )

            
            }
           

            <Drawer
                    open={this.state.openOptionInfo}
                        onRequestClose={this.closeInfo}
                        modalElementClass="modalInfo">
                        <div>
                        <div onClick={this.closeInfo} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '12px', color: 'white', marginTop: '-25px', position: 'absolute'}}>
                        chiudi
                        </div>

                        <iframe style={{ width: '100%', border: 'none', height: '450px', borderRadius: '12px'}} src={this.state.external_url} />            
                        </div>
                    </Drawer>
            </div>
        )
    }
}

export default BookingDetailsOptions
