import React, { Component } from 'react';
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { it, enUS, ja, es } from 'date-fns/locale';
import './App.css';
import ThemeManager from './ThemeManager';
import i18n from "i18next";
import OngoingEvents from './OngoingEvents';
import i18next from 'i18next';

class BookingDate extends Component {

    constructor(props){
        super(props);
        
        var lang = enUS;
        var currentLang = i18next.language;
        if(currentLang == 'it'){
            lang = it;
        }else if(currentLang == 'ja'){
            lang = ja;
        }else if(currentLang == 'es'){
            lang = es;
        }

        var datesArray = [];
        const totalElement = this.props.dates.length;
        var lastDate = this.props.dates[0];
        var firstDate = this.props.dates[0];
        this.props.dates.map(function(item, i){

            var date1 = new Date(item);
            var date2 = new Date(lastDate);
            var date3 = new Date(firstDate);

            if(date1.getTime() > date2.getTime()){
                lastDate = item;
            }

            if(date1.getTime() < date3.getTime()){
                firstDate = item;                
            }


            var textualDateProv = new Intl.DateTimeFormat("it-IT", {year: "numeric", month: "2-digit",day: "2-digit"
        }).format(new Date(item));
        datesArray.push(textualDateProv);
        });


        var date = new Date(firstDate);
        var textualLastDate = new Intl.DateTimeFormat("it-IT", {
            day: "2-digit",
                month: "2-digit",
                year: "numeric"
          }).format(new Date(lastDate));
        var removeDates = [];
        var i = 0;
        while(true){
            i++;
           
            if(i>300){
                break;
            }
            date.setDate(date.getDate() + 1);
            
            var textualDate = new Intl.DateTimeFormat("it-IT", {year: "numeric", month: "2-digit",day: "2-digit"
              }).format(date);

            if(textualDate == textualLastDate){
                break;
            }else{
                                  
                if(!datesArray.includes(textualDate)){
                    removeDates.push(new Date(date));
                }
            }

        }

      
        this.state = { 
            disableDates: removeDates, 
            dates: [], selectedDate: '', 
            date: new Date(firstDate), 
            minDate: new Date(firstDate), 
            maxDate: new Date(lastDate), 
            showCancel: props.showCancel,
            userInfo: props.user,
            lang: lang,
            onGoingEvents: props.onGoingEvents
        };
        
      }


      confirmDate = (e) => {
  
            this.props.selectDate(this.state.date);
      }

      cancel = (e) => {
          this.props.showBooking();
      }

      onChange = (date) => {
  
          this.setState({ date: date});
      }


      state = {
        selectedOption: null,
      };
      handleChange = selectedOption => {
        this.setState(
          { selectedOption },
          () => console.log('')
        );
      };
    

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        
        return (
            <div>

                 <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>

                 <div style={{marginTop: '-25px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t('for.what')} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{i18n.t('day')}</p>{i18n.t('want.book')}
            </div>
            
            <OngoingEvents events={this.state.onGoingEvents} />
            

            <div style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >      

            
            <Calendar color={_selectionColor} date={this.state.date} disabledDates={this.state.disableDates} onChange={this.onChange} locale={this.state.lang} minDate={this.state.minDate} maxDate={this.state.maxDate}  />
            

            </div>
            
                  <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmDate}
            >
                     {i18n.t('next')}</p>
                    </div>

                    {
                        this.state.showCancel
                   ? (
                    <div onClick={this.cancel} style={{ cursor: 'pointer', fontSize: '14px', color: _mainTextColor}}> {i18n.t('cancel')}</div>
                   )
                   : (
                     null
                   )
                    }
                    


                    <br />
                    <br />
            </div>
        )
    }
}
//  <Calendar color="#ffc200" date={this.state.date} disabledDates={this.state.disableDates} onChange={this.onChange} locale={it} minDate={this.state.minDate} maxDate={this.state.maxDate}  />
                  
export default BookingDate
