import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";



export class BookingDetailsMini extends Component {

    constructor(props){
        super(props);
        var bookingColor = 'rgb(255,194,0)';
        if(props.info.status=="1"){
            bookingColor = 'green';
        }
        this.state = { info: props.info, bookingColor: bookingColor};
    }

    onItemClick = (e) => {
        if(this.state.info.url != ''){
            var newLocation = "/"+this.state.info.url;
            window.location.href = newLocation;
            return;
        }
        this.props.selectBooking(this.state.info.date);
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                  <div style={{marginTop: '15px', width: '250px', height: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _secondaryTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                onClick={this.onItemClick} >
                    <div style={{ width: '40px'}}>

                    {
                   this.state.info.type === 'normal'
                   ? (
                    <p style={{fontSize: '18px'}}>📆</p>
                   )
                   : (
                      <p style={{fontSize: '20px'}}>🥳</p>
                   )
                }

                    </div>
                    <div style={{ width: '190px', textAlign: 'left', 
                justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                    {i18n.t(this.state.info.dateArray.day)}{this.state.info.dateArray.number}{i18n.t(this.state.info.dateArray.month)}
                    <br />
                    {this.state.info.hour} {i18n.t('for.people')} {this.state.info.guests}&nbsp;
                    
                    {
                   this.state.info.guests === '1'
                   ? (
                     i18n.t('guest')
                   )
                   : (
                     i18n.t('guests')
                   )
                }
                    </div>

                    

                    <div style={{ height: '10px', marginLeft: '-25px', backgroundColor: this.state.bookingColor, width: '10px', borderRadius: '5px', textAlign: 'left', 
                justifyContent: 'left', alignItems: 'left'}}></div>
                    
                    </div>
            </div>
        )
    }
}

export default BookingDetailsMini
