import React, { Component } from 'react'
import BookingTable from './BookingTable';
import BookingDetails from './BookingDetails';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class NormalBooking extends Component {


    constructor(props){
        super(props);
      
        var userInfo = props.user;
        var normalBooking = props.info;
        userInfo.token = normalBooking.token;

        this.state = { normalBooking: normalBooking, userInfo: userInfo, existsBooking: props.existsBooking };
      
      }

      cancelBooking = () => {
          this.props.bookNow();
      }

      goBack = () => {
        this.props.bookNow();
      }

      goToDate = () => {
            this.props.bookNow();
         // this.props.showBooking(this.state.normalBooking.datevalue);
      }
    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                 <div style={{marginTop: '15px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <div style={{marginTop: '3px', marginLeft: '40px',  height: '25px', borderRadius: '8px', width: '20px', color: _secondaryTextColor, fontSize: '12px', textAlign: 'center'}}>
            📆         
            </div> 
            <div style={{marginLeft: '10px',  height: '25px', borderRadius: '8px', width: '200px', color: _secondaryTextColor, fontWeight: 'normal', fontSize: '16px', textAlign: 'left'}}>
            {i18n.t(this.state.normalBooking.dateArray.day)}{this.state.normalBooking.dateArray.number}{i18n.t(this.state.normalBooking.dateArray.month)}     
            </div>        
            </div>

            
               
              

                {
                   this.state.existsBooking === null 
                   ? (
                    <BookingTable normal={true} bookAction={this.goToDate} user={this.state.userInfo} event={this.state.normalBooking} canCancel={true} cancel={this.cancelBooking} />
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.existsBooking !== null 
                   ? (
                    <BookingDetails user={this.state.userInfo} eventinfo={this.state.normalBooking} back={this.goBack} info={this.state.existsBooking} backOption="indietro" />
                   )
                   : (
                       null
                   )
                }   


            


            </div>
        )
    }
}

export default NormalBooking
