import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class MainInfo extends Component {



    constructor(props){
        super(props);
        
        this.state = { eventInfo: props.info};
        
    }

    static getDerivedStateFromProps(props, current_state) {
        
        if(current_state.eventInfo !== props.info){
            return {
                eventInfo: props.info
              }
        }

        
        return null;
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        
        return (
            <div>
                { (this.state.eventInfo.image != null && this.state.eventInfo.image.includes('https://')) &&
                <div style={{ position: 'relative'}}>
                <img style={{ marginTop: '0px', width: '100%', height: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.eventInfo.image}
                alt="content"
                />
                </div>
                }
                <br />
                <div style={{marginTop: '0px' }} >               
            { (this.state.eventInfo.eventName !== '' && this.state.eventInfo.eventName !== ' ') &&
            <div style={{marginTop: '0px', marginLeft: '50px', marginRight: '50px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <div style={{ borderRadius: '8px', color: _mainTextColor, fontSize: '22px', fontWeight: 'bold', textAlign: 'center'}}>
            {this.state.eventInfo.eventName}           
            </div>        
            </div>
            }


            { this.state.eventInfo.clubName !== '' &&
           <div style={{marginTop: '5px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
           <div style={{marginTop: '3px',  borderRadius: '8px', width: '20px', color: _secondaryTextColor, fontSize: '14px', textAlign: 'left'}}>
           📍         
           </div> 
           <div style={{marginLeft: '10px', borderRadius: '8px', color: _secondaryTextColor, fontWeight: 'normal', fontSize: '15px', textAlign: 'left'}}>
            {this.state.eventInfo.clubName}           
           </div>        
           </div>
            }


            { (this.state.eventInfo.eventDateArray !== '' && this.state.eventInfo.eventDateArray !== undefined) &&
            <div style={{marginTop: '5px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <div style={{marginTop: '3px',  height: '25px', borderRadius: '8px', width: '20px', color: _secondaryTextColor, fontSize: '12px', textAlign: 'left'}}>
            📆       
            </div> 
            <div style={{marginLeft: '10px',  height: '25px', borderRadius: '8px', color: _secondaryTextColor, fontWeight: 'normal', fontSize: '15px', textAlign: 'left'}}>
            {i18n.t(this.state.eventInfo.eventDateArray.day)}{this.state.eventInfo.eventDateArray.number}{i18n.t(this.state.eventInfo.eventDateArray.month)}      
            </div>        
            </div>
            }

            </div>
                
            </div>
        )
    }
}

export default MainInfo

/*
                <div style={{ position: 'absolute', marginTop: '-270px', background: 'linear-gradient(rgb(255,255,255), transparent)', width: '100%', height: '250px'}}>

                </div>
                */