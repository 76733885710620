import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingSingleOptionQuantity extends Component {

    constructor(props){
        super(props);

        this.state = { 
            details: props.details,
            selected: props.selected,
            quantity: 0,
            maxQuantity: props.maxQuantity,
            globalQuantity: props.globalQuantity,
            showPlus: '+',
            showMinus: '',
            openOptionInfo: false
        };
    }


    static getDerivedStateFromProps(props, current_state) {
        
        if(current_state.maxQuantity !== props.maxQuantity ){

            return {
                maxQuantity: props.maxQuantity,
                globalQuantity: props.globalQuantity,
                quantity: 0,
                showPlus: '+',
                showMinus: ''
              }
        }

        if(current_state.globalQuantity !== props.globalQuantity){
            var showPlus = '+';
            if((props.maxQuantity-props.globalQuantity) == 0){
               showPlus = '';
            }          

            return {
                maxQuantity: props.maxQuantity,
                globalQuantity: props.globalQuantity,
                showPlus: showPlus
              }
        }
        
        return null;
    }



    addGuest = (e) => {
        if(this.state.globalQuantity<this.state.maxQuantity){
            this.setState({ quantity: this.state.quantity+1, showMinus: '-'});
            this.props.selectQuantity(this.props.slot,this.props.index,true);

            if((this.state.maxQuantity-this.state.globalQuantity) == 1){

                this.setState({ showPlus: ''});
            }
        }
    }

    removeGuest = (e) => {

        if(this.state.quantity>0){
            this.setState({ quantity: this.state.quantity-1});
            this.props.selectQuantity(this.props.slot,this.props.index,false);

            if((this.state.maxQuantity-this.state.globalQuantity) == 1 && this.state.quantity!=1){

                this.setState({ showPlus: ''});
            }

            if(this.state.quantity==1){
                this.setState({ showMinus: ''});
            }
            
        }
       
    }

    closeInfo = () => {
        this.setState({ openOptionInfo: false })
    }

    openInfo = () => {
        const newWindow = window.open(this.state.details.external_url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                 <div style={{ width: '250px', minHeight: '60px', padding: '5px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                >
                    <div style={{ marginLeft: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>

                    <div style={{ width: '65%', textAlign: 'left'}}>
                    <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> {this.state.details.element_name}</div>
                    { this.state.details.external_url != '' &&
                    <div onClick={this.openInfo} style={{ color: _selectionColor, fontSize: '10px', cursor: 'pointer', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> {i18n.t('show.more')}</div>
                    }
                    </div>

                    <div  style={{ marginLeft: '15px', width: '100px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '60%'}}>
                    <div style={{ color: _secondaryTextColor, fontSize: '28px', cursor: 'pointer', width: '10px'}} onClick={this.removeGuest}>{this.state.showMinus}</div>
                    <div style={{ marginLeft: '20px', marginRight: '20px', color: _mainTextColor, fontSize: '20px'}}>{this.state.quantity}</div>
                    <div style={{ color: _secondaryTextColor, fontSize: '28px', cursor: 'pointer', width: '10px'}} onClick={this.addGuest}>{this.state.showPlus}</div>
                    </div>
                    
                    
                    </div>

                    </div>

                 

            </div>
        )
    }
}

export default BookingSingleOptionQuantity
