import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import BookingDetailsOptions from './BookingDetailsOptions';
import BookingShare from './BookingShare';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingDetails extends Component {

    constructor(props){
        super(props);
        
      
        this.state = { eventInfo: props.eventinfo, bookRecap: props.info, userInfo: props.user, bookingerror: false, openCancel: false, isProcessing: false, openEdit: false, backOption: props.backOption, isCancel: false};
        
    }

    toggle = () => {
       this.setState({ openCancel: false, openEdit: false })
    }
      
    confirmCancel = (e) => {
        this.setState({ openCancel: true, isCancel: true })
    }

    confirmEdit = (e) => {
        this.setState({ openEdit: true })
    }

    backButton = (e) => {
        this.props.back();
    }

    openInvoice = (e) => {
        const newWindow = window.open(this.state.bookRecap.stripe_proforma, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    cancelBooking = (e) => {
        
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true});
            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.bookRecap.token,
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/booking/cancel';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
          
                if(r==null){
                    if(this.state.isCancel){
                        this.props.back();
                    }else{                      
                        window.location.reload();
                    }
                    
                }else{
                   //error
                    this.setState({ bookingerror: true, isProcessing: false});
                }
                
                this.toggle();
              }).catch(e => {
              
                this.setState({ bookingerror: true, isProcessing: false});
                this.toggle();
              });
        }
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
    
        return (
            <div>
                <div style={{}}>
                { this.state.bookingerror && <p style={{ marginTop: '20px', color: _errorColor }}>{i18n.t('request.error.reload')}</p> }

                <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
                <div style={{ marginTop: '-10px', color: _secondaryTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px' }}>
                {i18n.t('here.your.booking')}
                    
                    
                    </div>

                { this.state.bookRecap.status === '0' &&
                    <div>
                    <p style={{ marginTop: '25px', color: _mainTextColor, fontWeight: 'bold', fontSize: '30px'}}> 
                    {i18n.t('in.acceptance')}
                    </p>
                    <p style={{ marginTop: '-25px', color: _mainTextColor, fontWeight: 'normal', fontSize: '14px'}}> 
                    {i18n.t('will.receive.sms')}
                    </p>
                    </div>
                }

                { this.state.bookRecap.status === '1' &&
                    <div>
                    <p style={{ marginTop: '25px', color: _mainTextColor, fontWeight: 'bold', fontSize: '24px'}}> 
                    {this.state.bookRecap.code} 
                    </p>
                    <div>
                    <QRCode value={this.state.bookRecap.code} bgColor="white" fgColor="black" level="H" includeMargin={true}/>
                    </div>
                    </div>
                }


               

                    <div style={{ fontSize: '14px', marginTop: '10px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: _mainTextColor }}>
                    <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} >
                    <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style={{ width: '50%', textAlign: 'left'}}>
                    <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '65%', textAlign: 'left', marginLeft: '15px'}}>{i18n.t('guests')}</div>
                    </div>
                    <div style={{ marginLeft: '50px', marginRight: '20px', color: _mainTextColor, fontSize: '20px', fontWeight: 'bold', width: '30%'}}>{this.state.bookRecap.guests}</div>
                    </div>
                    </div>
                    </div>
                    </div>

                  

                    <div style={{ fontSize: '14px', marginTop: '0px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: _mainTextColor }}>
                    <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} >
                    <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style={{ width: '50%', textAlign: 'left'}}>
                    <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '65%', textAlign: 'left', marginLeft: '15px'}}>{i18n.t('hour')}</div>
                    </div>
                    <div style={{ marginLeft: '50px', marginRight: '20px', color: _mainTextColor, fontSize: '16px', fontWeight: 'bold', width: '30%'}}>{this.state.bookRecap.hour}</div>
                    </div>
                    </div>
                    </div>
                    </div>
                    

                    


                {(this.state.bookRecap.options!=undefined && this.state.bookRecap.options.length>0) &&
                <BookingDetailsOptions options={this.state.bookRecap.options} />    
                }


                { this.state.bookRecap.comments !== '' && <div style={{ marginTop: '16px', color: _mainTextColor }}>
                    
                <div style={{ marginTop: '50px', color: _mainTextColor, fontSize: '15px', fontWeight: 'normal'}}>
                  { this.state.eventInfo.comments_text != '' && this.state.eventInfo.comments_text}
                  { this.state.eventInfo.comments_text == '' && 'Note'}
                </div>

                <p style={{marginTop: '5px', color: _secondaryTextColor, fontSize: '14px'}}>
                    {this.state.bookRecap.comments}
                </p>
                </div>
                }

                { (this.state.bookRecap.payment !== null && this.state.bookRecap.payment !== "0" && this.state.bookRecap.payment !== 0) &&
                <div><div style={{ fontSize: '14px', marginTop: '30px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {i18n.t('your.deposit')}
                    <p style={{ marginLeft:'10px', marginRight:'4px', color: _selectionColor, fontWeight: 'bold', fontSize: '16px'}}> 
                     {(parseFloat(this.state.bookRecap.payment)/100).toFixed(2)}
                    </p>
                    €
                    </div>
                    <p onClick={this.openInvoice} style={{ cursor: 'pointer', marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _selectionColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    {i18n.t('show.receipt')}
                    </p>

                    <p style={{ marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _secondaryTextColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    {i18n.t('fiscal.receipt')} 
                    </p>
                   
                    </div>
                }
                
                </div>

            
                <br />
                <br />
                
               


                   
{ !(this.state.bookRecap.payment !== null && this.state.bookRecap.payment !== "0" && this.state.bookRecap.payment !== 0) &&
                    <div onClick={this.confirmCancel} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor, fontWeight: 'bold', marginTop: '5px'}}>{i18n.t('cancel.booking')} </div>
                    
}
<br />
<br />

                    { 
                    <div onClick={this.backButton} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor }}>{i18n.t('back')}</div>

                    }
                   
                    <br />


                    <Drawer
                    open={this.state.openCancel}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('want.cancel')} <br />{i18n.t('your.booking')}</div>
                    </div>
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.cancelBooking}
            >{i18n.t('cancel')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    </div>
                    </div>
                    </Drawer>


                  

            </div>
        )
    }
}

export default BookingDetails

/*
 <div style={{ fontSize: '14px', marginTop: '30px', color: 'white', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Prenotazione per  
                    <p style={{ marginLeft:'8px', marginRight:'8px', color: 'rgb(255,194,0)', fontWeight: 'bold', fontSize: '16px'}}> 
                    {this.state.bookRecap.guests} 
                    </p>
                    persone
                    </div>
                <div style={{ marginTop: '-30px', color: 'white', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' , fontSize: '14px' }}>
                    alle ore
                    <p style={{ marginLeft:'10px', marginRight:'0px', color: 'rgb(255,194,0)', fontWeight: 'bold', fontSize: '16px'}}> 
                    {this.state.bookRecap.hour}
                    </p> 
                    </div>

*/



/*
PULSANTE MODIFICA


                { !(this.state.bookRecap.payment !== null && this.state.bookRecap.payment !== "0" && this.state.bookRecap.payment !== 0) &&
                <div><div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmEdit}
            >

                    Modifica</p>
                    </div>
                    </div>
            }


*/