import React, { Component } from 'react'
import BookingHour from './BookingHour';
import './Hours.css';
import ThemeManager from './ThemeManager';

export class BookingHours extends Component {

    constructor(props){
        super(props);
        var hours = [];
        var hourslot = [];
        const lastIndex = this.props.hours.length - 1;

        this.props.hours.map(function(item, i){
            if(i%4===3){
                var houritem = [];
                houritem.hour = item;
                houritem.selected = false;
                hourslot.push(houritem);
                hours.push(hourslot);
                hourslot = [];
            }else if(i === lastIndex){
                var houritem = [];
                houritem.hour = item;
                houritem.selected = false;
                hourslot.push(houritem);
                hours.push(hourslot);
                hourslot = [];
            }else{
                var houritem = [];
                houritem.hour = item;
                houritem.selected = false;
                hourslot.push(houritem);
            }
        });

        this.state = { hours: hours, selected: 0};
    }


    selectHour = (slot,index,select) => {
        var hours = this.state.hours;
        for(var i=0; i<hours.length; i++){
            var thisSlot = hours[i];
            for(var j=0; j<thisSlot.length; j++){
                thisSlot[j].selected = false;
            }
        }

        if(select){
            hours[slot][index].selected = true;
            this.props.selectHour(hours[slot][index].hour);
        }else{
            this.props.selectHour('');
        }
        
        this.setState({ hours: hours});
    }
 
    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        var colors = [];
        this.state.hours.map(function(item, i){
            colors.push(_itemBackgroundColor);
        });


        return (
            <div>

            {
           this.state.hours.map((hourSlot, i) =>
               
               
                
                    <div key={i}  style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>       
                
                   { hourSlot.map((houritem, k) =>
                   
                      
                            <BookingHour selected={houritem.selected} key={k + i*3} slot={i} index={k} selectThis={this.selectHour} hour={houritem.hour} />
                      

                    )

                    }
                
                    </div>
                
                )
             
             
            
            }
            

            </div>
        )
    }
}

export default BookingHours


/*
<div key={thisIndex} style={{backgroundColor: colors[thisIndex], marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', borderRadius: '8px', color: 'white', textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}}  
                    
                    >
                            {hour} 
                            </div>

*/

/*
<div style={{ width: '70%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: 'black'}}>

                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[0], borderRadius: '8px', color: 'white', textAlign: 'center', 
               justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
               id="0" 
               onMouseOver={this.onItemHover} 
               onMouseLeave={this.onItemLeave}
               onClick={this.onItemClick} >
                   uomo
               </div>

               <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[0], borderRadius: '8px', color: 'white', textAlign: 'center', 
               justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
               id="0" 
               onMouseOver={this.onItemHover} 
               onMouseLeave={this.onItemLeave}
               onClick={this.onItemClick} >
                   uomo
               </div>

               <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[0], borderRadius: '8px', color: 'white', textAlign: 'center', 
               justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
               id="0" 
               onMouseOver={this.onItemHover} 
               onMouseLeave={this.onItemLeave}
               onClick={this.onItemClick} >
                   uomo
               </div>
               </div>
*/




//2


/*
 <div>

            {
           hours.map(function(hourSlot, i){
               
               return (
                
                    <div key={i}  style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: 'black'}}>       
                
                   { hourSlot.map(function(hour, k){ 
                    var thisIndex = k + i*3;
                        return (
                            <BookingHour key={thisIndex} hour={hour} />
                        )

                    })

                }
                
                    </div>
                
                )
             
             
            })
            }

            </div>

*/