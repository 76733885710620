import React, { Component } from 'react'
import PaymentOption from './PaymentOption';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingSingle extends Component {

    intervalID = 0;

    constructor(props){
        super(props);
        this.state = {  userInfo: props.user, eventInfo: props.event, isBooking: false, covid: true, showConfirm: true, bookingerror: false, animationShake: '' };
       
    }


    bookNow = () => {

        if(this.state.covid){
            this.confirmBook();
        }else{
            this.setState({ animationShake: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }      
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: ''}); 
        clearInterval(this.intervalID);
    }

    checkboxAction = (e) => {

        if(!this.state.covid){
            this.setState({ showConfirm: true, covid: true}); 
        }else{
            this.setState({ showConfirm: true, covid: false});  
        }
    }

    confirmBook = () => {
       
        if(!this.state.isBooking){
            this.setState({ isBooking: true});
            

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.userInfo.token,
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/booking/alone';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
                
                if(r==null){
                   window.location.reload();                           
                }else{
                   //error
                    this.setState({ bookingerror: true});
                }
                
         
              }).catch(e => {
                  
                    this.setState({ bookingerror: true});
              });
        }
        

    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>

            { this.state.bookingerror && <p style={{ marginTop: '50px', color: _errorColor }}>{i18n.t('request.error.reload')}</p> }

            
            <div style={{marginTop: '-20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>

                 <div style={{ marginTop: '-20px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px' }}>
                    {i18n.t('you.are.booking')}
                    <p style={{ marginLeft:'8px', marginRight:'8px', color: _selectionColor, fontWeight: 'bold', fontSize: '14px'}}> 
                    {i18n.t('single.enter')}
                    </p>
                    </div>


                 
              

                <div><div  style={{ marginTop: '25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.bookNow}
            >

            {i18n.t('book.now')}</p>
                    </div>
                    </div>


            </div>
        )
    }
}

export default BookingSingle
