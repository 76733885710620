import React, { Component } from 'react'
import './App.css';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import BookingDetailsOptions from './BookingDetailsOptions';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class RemoteBooking extends Component {

    intervalID = 0;

    constructor(props){
        super(props);

        var covidStatus = true;
        if(props.booking.hide_covid == '1'){
            covidStatus = false;
        }

        this.state = { bookRecap: props.booking, showCovid: covidStatus, covid: !covidStatus, showConfirm: true, error: false, animationShake: '', isCancel: false, openCancel: false };
        
      }


      cancel = (e) => {
        this.props.cancel();
    }

    toggle = () => {
        this.setState({ openCancel: false })
     }

    confirmCancel = (e) => {
        this.setState({ openCancel: true, isCancel: true })
    }

    cancelBooking = (e) => {
        this.props.cancelBook();
    }

    confirmBook = (e) => {
        if(this.state.covid){
            this.props.confirmBook();
        }else{
            this.setState({ animationShake: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }      
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: ''}); 
        clearInterval(this.intervalID);
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.error !== props.error) {          
            return {
                error: props.error          
            }
        }      
        return null;
    }


    checkboxAction = (e) => {

        if(!this.state.covid){
            this.setState({ showConfirm: true, covid: true}); 
        }else{
            this.setState({ showConfirm: true, covid: false});  
        }
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                <div style={{}}>

                { this.state.error && <p style={{ marginTop: '10px', color: _errorColor }}>{i18n.t('request.error.reload')}</p> }


                <div style={{ fontSize: '14px', marginTop: '30px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: _mainTextColor }}>
                    <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} >
                    <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style={{ width: '50%', textAlign: 'left'}}>
                    <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '65%', textAlign: 'left', marginLeft: '15px'}}>{i18n.t('guests')}</div>
                    </div>
                    <div style={{ marginLeft: '50px', marginRight: '20px', color: _mainTextColor, fontSize: '20px', fontWeight: 'bold', width: '30%'}}>{this.state.bookRecap.guests}</div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div style={{ fontSize: '14px', marginTop: '0px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: _mainTextColor }}>
                    <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} >
                    <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <div style={{ width: '50%', textAlign: 'left'}}>
                    <div style={{ color: _mainTextColor, fontSize: '14px', fontWeight: 'normal', width: '65%', textAlign: 'left', marginLeft: '15px'}}>{i18n.t('hour')}</div>
                    </div>
                    <div style={{ marginLeft: '50px', marginRight: '20px', color: _mainTextColor, fontSize: '16px', fontWeight: 'bold', width: '30%'}}>{this.state.bookRecap.hour}</div>
                    </div>
                    </div>
                    </div>
                    </div>

                {(this.state.bookRecap.options!=undefined && this.state.bookRecap.options.length>0) &&
                <BookingDetailsOptions options={this.state.bookRecap.options} />    
                }

{ this.state.bookRecap.comments !== '' && <div style={{ marginTop: '20px', color: _mainTextColor }}>
                    { this.state.bookRecap.comments_text != '' && this.state.bookRecap.comments_text}
                  { this.state.bookRecap.comments_text == '' && i18n.t('notes')}
<p style={{marginTop: '5px', color: _secondaryTextColor, fontSize: '15px'}}>
{this.state.bookRecap.comments}
</p>
</div>
}

</div>



{this.state.showCovid && 
<div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '300px', marginTop: '35px', fontSize: '14px', color: _secondaryTextColor}}>
<p style={{ color: _mainTextColor, fontWeight: 'bold'}}>{i18n.t('covid.check')}</p>
<p style={{ color: _secondaryTextColor, fontWeight: 'normal', fontSize: '12px'}}>
{i18n.t('covid.text')}<br /> 
<a target="_blank" style={{ marginLeft: '5px', fontWeight: 'bold', color: _selectionColor, textDecoration: 'none'}} href="https://www.getfy.it/agreements/covid-policy.pdf">
{i18n.t('this.link')}
</a>
</p>
<div style={{ marginTop: '25px', color: _mainTextColor, fontWeight: 'bold', fontSize: '12px'}}>{i18n.t('declare')} 
<div><input className={this.state.animationShake} style={{marginTop: '3px'}} checked={this.state.covid} onChange={this.checkboxAction} type="checkbox" /> </div>
</div>
</div>
    }

<br />

{ this.state.showConfirm &&
<div><div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
<p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
onClick={this.confirmBook}
>

{i18n.t('confirm')}</p>
</div>
</div>

}
<br />
<div onClick={this.confirmCancel} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor}}>{i18n.t('decline')}</div>

<br />
<br />

<Drawer
                    open={this.state.openCancel}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('want.cancel')} <br />{i18n.t('your.booking')}</div>
                    </div>
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.cancelBooking}
            >{i18n.t('cancel')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('back')}</div>
                    </div>
                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default RemoteBooking
