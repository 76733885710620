import React, { Component } from 'react'
import BookingHours from './BookingHours';
import './App.css';
import BookingOptions from './BookingOptions';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingNew extends Component {


    constructor(props){
        super(props);
        var guests = 0;
        var comments = '';
        var hour = '';
        var showGuests = false;
        var showCovid = false;

        var maxGuests = 50;
        if(this.props.maxGuests !== undefined){
            maxGuests = this.props.maxGuests;
        }

        if(maxGuests == '1'){
            guests = 1;
           
        }
        
        this.state = { guests: guests, 
                    commentsValue: comments, 
                    selectedHour: hour, 
                    covid: false, 
                    showGuests: showGuests, 
                    showCovid: showCovid, 
                    showBook: false,
                    showOptions: false,
                    userInfo: props.user,
                    eventInfo: props.info,
                    canCancel: props.canCancel,
                    optionStatus: '',
                    optionRecap: [],
                    maxGuests: maxGuests
                };
       
    }


    optionStatus = (status) => {
 
        if(status){
            this.setState({ showBook: true});  
        }else{
            this.setState({ showBook: false});  
        }

    }

    selectOption = (options, recapOptions) => {
      
        
        this.setState({ optionStatus: options, optionRecap: recapOptions});  
        
    }


    addGuest = (e) => {
        if(this.state.guests<this.state.maxGuests){
            this.setState({ guests: this.state.guests+1});
            this.showNextSection(this.state.guests+1);
        }
    }

    removeGuest = (e) => {

        if(this.state.guests>0){
            this.setState({ guests: this.state.guests-1});
            this.showNextSection(this.state.guests-1);
        }
       
    }

    checkboxAction = (e) => {

        if(!this.state.covid){
            this.setState({ showBook: true, covid: true}); 
        }else{
            this.setState({ showBook: false, covid: false});  
        }
    }

    showNextSection = (guests) => {
        if(guests>0){
            if(this.state.eventInfo.options!=null && this.state.eventInfo.options.length>0){
                this.setState({ showOptions: true});  
            }else{
                this.setState({ showBook: true});  
            }
                    
        }else{
            this.setState({ showBook: false, showOptions: false});  
        }
    }

    hourSelected = (hour) => {
        if(hour===''){
            this.setState({ showRecap: false, showSelection: true, showGuests: false, showOptions: false, showCovid: false, showBook: false, selectedHour: ''});
        }else{
            if(this.state.guests>0){            
                if(this.state.covid){
                    this.setState({ showGuests: true, showCovid: true, showBook: true, selectedHour: hour});
                }else{
                    this.setState({ showGuests: true, showCovid: true, showOptions: true, selectedHour: hour});
                }
            }else{
                this.setState({ showGuests: true, selectedHour: hour});
            }        
        }
    }

    changeComment = (e) => {
        this.setState({ commentsValue: e.target.value});
    }


    confirmBook = (e) => {
        if(this.state.guests > 0 && this.state.selectedHour !== ''){
           this.props.bookNow(this.state.guests, this.state.selectedHour, this.state.commentsValue, this.state.optionStatus, this.state.optionRecap);
        }
    }

    cancel = (e) => {
        this.props.cancel();
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                
                <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>

                { this.state.eventInfo.hours.length !== 0 && 
                <div style={{ marginTop: '-10px', color: _secondaryTextColor, fontSize: '14px', fontWeight: 'normal'}}>{i18n.t('booking.hour')}</div>
                }
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ width: '70%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                
                <BookingHours hours={this.state.eventInfo.hours} selectHour={this.hourSelected} />

                { this.state.eventInfo.hours.length === 0 && 
                    <div> 
                    
                    <div style={{ marginTop: '25px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>{i18n.t('cant.booking')}</div>
                    <div style={{ marginTop: '5px', color: _secondaryTextColor, fontSize: '25px', fontWeight: 'bold'}}>{i18n.t('sold.out')}</div>
                    

                    </div> 
                }

                </div>
                </div>
                { (this.state.showGuests && this.state.maxGuests>1) &&
                <div><div style={{ marginTop: '50px', color: _secondaryTextColor, fontSize: '14px', fontWeight: 'normal'}}>{i18n.t('how.many')}</div>
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ color: _secondaryTextColor, fontSize: '28px', cursor: 'pointer'}} onClick={this.removeGuest}>-</div>
                <div style={{ marginLeft: '20px', marginRight: '20px', color: _mainTextColor, fontSize: '30px'}}>{this.state.guests}</div>
                <div style={{ color: _secondaryTextColor, fontSize: '28px', cursor: 'pointer'}} onClick={this.addGuest}>+</div>
                </div></div>
                }

                
                { this.state.showCovid &&
                <div>
                <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                
                </div>

                
                </div>
                }


                { this.state.showOptions && 

                <BookingOptions guests={this.state.guests} status={this.optionStatus} select={this.selectOption} options={this.state.eventInfo.options} />

                }


            { this.state.showBook &&
            <div>
                <div style={{ marginTop: '50px', color: _mainTextColor, fontSize: '15px', fontWeight: 'normal'}}>
                  { this.state.eventInfo.comments_text != '' && this.state.eventInfo.comments_text}
                  { this.state.eventInfo.comments_text == '' && 'Note'}
                </div>
            <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: _mainTextColor, fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="comments"
                value={this.state.commentsValue}
                placeholder={i18n.t('type.here')} 
                onChange={this.changeComment}
                />
               </div>
                <br />
                <div  style={{marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmBook}
            >
                    {i18n.t('next')}</p>
                    </div>
                   </div> 
            }

                    {
                        this.state.canCancel
                   ? (
                    <div onClick={this.cancel} style={{ marginTop: '50px', cursor: 'pointer', fontSize: '14px', color: _mainTextColor }}>{i18n.t('back')}</div>
                   )
                   : (
                     null
                   )
                    }

                    <br />
                    <br />
                    <br />
            </div>
        )
    }
}

export default BookingNew
/*
<BookingHour selected={false} selectThis={this.hourSelected} hour="19:00" />
                <BookingHour selected={false} selectThis={this.hourSelected} hour="19:30" />
                <BookingHour selected={false} selectThis={this.hourSelected} hour="20:00" />
                <BookingHour selected={false} selectThis={this.hourSelected} hour="20:30" />
*/