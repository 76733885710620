import React, { Component } from 'react'
import PaymentOption from './PaymentOption';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import isok from './isok.png';
import notok from './notok.png';
import secure from './secure.png';
import Iframe from 'react-iframe'
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class RemotePayment extends Component {


    constructor(props){
        super(props);

        this.state = { bookRecap: props.booking, userInfo: props.user, openSuccess: false, openFail: false, openConfirmSecure: false, redirectUrl: '', canPay: true, openCancel: false, failText: 'Riprova ora' };
        
      }


    toggle = () => {
        window.location.reload();
     }

     confirmCancel = () => {
        this.setState({ openCancel: true });
     }

     toggleDecline = () => {
        this.setState({ openCancel: false });
     }

     goToBankSite = (e) => {

        window.location.href = this.state.redirectUrl;

     }

     cancelBooking = (e) => {
         this.props.cancelBook();
     }

      payNow = (card,cvc,expiry) => {
            if(this.state.canPay){
            this.setState({ canPay: false});

            this.setState({ openLoader: true })

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
              sessiontoken = '';
            }
            
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        code:  this.state.userInfo.token,
                        token: sessiontoken,
                        card: card,
                        cvc: cvc,
                        expiry: expiry
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/user/v7/payment/';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => {
                
                if(r.info.status=='done'){
                    this.setState({ openSuccess: true });
                }else if(r.info.status=='3D'){
                    //prosegui banca popup
                    this.setState({ openConfirmSecure: true, redirectUrl: r.info.url });
                }else if(r.info.status=='error'){
                    //prosegui banca popup
                    this.setState({ openFail: true, failText: r.info.text });
                }else{
                    this.setState({ openFail: true, failText: 'Riprova ora' });
                }
                
              
            }).catch(e => {
               
                this.setState({ openFail: true });
            });
        }

    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                <div style={{ marginTop: '40px', color: _mainTextColor, fontSize: '30px', fontWeight: 'bold'}}>€ {(parseFloat(this.state.bookRecap.payment)/100).toFixed(2)}</div>
                <br />
                <PaymentOption pay={this.payNow} />         

                <br />
<div onClick={this.confirmCancel} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor }}>{i18n.t('decline')}</div>

<Drawer
                    open={this.state.openCancel}
                        onRequestClose={this.toggleDecline}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('want.cancel')} <br />{i18n.t('your.booking')}</div>
                    </div>
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.cancelBooking}
            >{i18n.t('decline')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggleDecline} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('back')}</div>
                    </div>
                    </div>
                    </Drawer>

                <Drawer
                    open={this.state.openSuccess}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('payment.ok')}</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '10px'}} src={isok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '10px', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('show.booking')}</div>
                    </div>
                   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openFail}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('anything.wrong')}<br /> {i18n.t('payment.fail')}</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '15px', width: '70px'}} src={notok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '15px', fontSize: '14px', color: _errorColor}}>{i18n.t('retry.now')}</div>
                    </div>
                   
                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.openConfirmSecure}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.goToBankSite}>
                            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('confirm.payment.1')}<br /> {i18n.t('confirm.payment.2')}</div>
                    </div>
                  
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.goToBankSite}
            >{i18n.t('next')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('cancel')}</div>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default RemotePayment
