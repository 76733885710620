import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class EventDetailsMini extends Component {

    constructor(props){
        super(props);
        var bookingColor = 'rgb(255,194,0)';
        if(props.info.status=="1"){
            bookingColor = 'green';
        }
        this.state = { info: props.info };
    }

    onItemClick = (e) => {

        var newLocation = "/"+this.state.info.code;
        window.location.href = newLocation;
        return;


    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                  <div style={{marginTop: '15px', width: '250px', backgroundColor: _itemBackgroundColor, borderRadius: '5px', color: _secondaryTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                onClick={this.onItemClick} >
                    <div style={{ marginLeft: '15px', width: '30px'}}>
                   <p style={{fontSize: '20px'}}>🥳</p>
                    </div>
                    <div style={{ width: '200px', textAlign: 'left', 
                justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                    <p style={{color: _mainTextColor, fontWeight: 'bold'}}>{this.state.info.name}</p>
                    <p style={{marginTop: '-10px'}}> {this.state.info.type} </p>
                    </div>                                    
                    </div>
            </div>
        )
    }
}

export default EventDetailsMini
