import React, { Component } from 'react'
import PaymentOption from './PaymentOption';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import isok from './isok.png';
import notok from './notok.png';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class BookingSinglePayment extends Component {

    intervalID = 0;

    constructor(props){
        super(props);
        
        this.state = {  userInfo: props.user, eventInfo: props.event, isBooking: false,
             covid: false, showConfirm: true, bookingerror: false, animationShake: '',
              showPayment: true, showCovid: false, openSuccess: false, openFail: false,
               openConfirmSecure: false, redirectUrl: '', canPay: true };
       
    }


    bookNow = () => {

        if(this.state.covid){
            this.confirmBook();
        }else{
            this.setState({ animationShake: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }      
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: ''}); 
        clearInterval(this.intervalID);
    }

    checkboxAction = (e) => {

        if(!this.state.covid){
            this.setState({ showConfirm: true, covid: true, showPayment: true, showCovid: false}); 
        }else{
            this.setState({ showConfirm: true, covid: false, showPayment: false,  showCovid: true});  
        }
    }

    cancelPayment = (e) => {
        this.setState({ showConfirm: true, covid: false, showPayment: false,  showCovid: true});  
    }

    toggle = () => {
        window.location.reload();
     }

     goToBankSite = (e) => {

        window.location.href = this.state.redirectUrl;

     }


    payNow = (card,cvc,expiry) => {
        if(this.state.canPay){
        this.setState({ canPay: false});

        this.setState({ openLoader: true })

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
        
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    code:  this.state.userInfo.token,
                    token: sessiontoken,
                    card: card,
                    cvc: cvc,
                    expiry: expiry
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/user/v7/payment/';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
            
            if(r.info.status=='done'){
                this.setState({ openSuccess: true });
            }else if(r.info.status=='3D'){
                //prosegui banca popup
                this.setState({ openConfirmSecure: true, redirectUrl: r.info.url });
            }else{
                this.setState({ openFail: true });
            }
            
          
        }).catch(e => {
           
            this.setState({ openFail: true });
        });
    }

}


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>

            { this.state.bookingerror && <p style={{ marginTop: '50px', color: 'red'}}>{i18n.t('request.error.reload')}</p> }

                 
            <div style={{marginTop: '-20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>

                 <div style={{ marginTop: '-20px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px' }}>
                    {i18n.t('you.are.booking')}
                    <p style={{ marginLeft:'8px', marginRight:'8px', color: _selectionColor, fontWeight: 'bold', fontSize: '14px'}}> 
                    {i18n.t('single.enter')} 
                    </p>
                </div>

           

                { (this.state.showPayment) &&
                    <div>
                        
                        <div style={{ marginTop: '10px', color: _mainTextColor, fontSize: '30px', fontWeight: 'bold'}}>€ {(parseFloat(this.state.eventInfo.payment)/100).toFixed(2)}</div>
                        <br />
                        <PaymentOption pay={this.payNow} />   
                    </div>
                }
                <br />
               
                
            <br />
            <br />

            <Drawer
                    open={this.state.openSuccess}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('payment.ok')}</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '10px'}} src={isok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '10px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('show.booking')}</div>
                    </div>
                   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openFail}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('anything.wrong')}<br /> {i18n.t('payment.fail')}</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '15px', width: '70px'}} src={notok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '15px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('retry.now')}</div>
                    </div>
                   
                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.openConfirmSecure}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.goToBankSite}>
                            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('confirm.payment.1')}<br /> {i18n.t('confirm.payment.2')}</div>
                    </div>
                  
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.goToBankSite}
            >{i18n.t('next')}</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('cancel')}</div>
                    </div>
                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default BookingSinglePayment
