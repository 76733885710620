import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class BookingShare extends Component {

    constructor(props){
        super(props);


    }



    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
               <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmEdit}
            >

                    Invia ad un amico</p>
                    </div>
            </div>
        )
    }
}

export default BookingShare

/*
<div style={{ fontSize: '14px', marginTop: '30px', color: 'white', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ color: 'white' }}>
                    <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: 'rgb(30,30,30)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                    justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} >
                    <div style={{ marginLeft: '20px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                   
                    <div style={{ marignLeft: '0px', width: '50%', textAlign: 'left'}}>
                    <div style={{ color: 'white', fontSize: '14px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> Condividi</div>              
                    <div  style={{ color: 'rgb(255,194,0)', fontSize: '10px', cursor: 'pointer', fontWeight: 'normal', width: '100%', textAlign: 'left'}}> mostra inviti</div>                    
                    </div>

                    <div style={{ marginLeft: '50px', marginRight: '10px', color: 'rgb(255,194,0)', fontSize: '30px', fontWeight: 'bold', width: '30%', cursor: 'pointer'}}>+</div>
                    </div>
                    </div>
                    </div>
                    </div>
*/