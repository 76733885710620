import logo from './getfy.svg';
import logoWhite from './logo-white.svg';
import logoBlack from './logo-black.svg';
import './App.css';
import { Component } from 'react';
import Landing from './Landing';
import BookingManager from './BookingManager';
import ThemeManager from './ThemeManager';
import MetaTags from 'react-meta-tags';
import UserProfile from './UserProfile';
import LangOption from './LangOption';
const utf8 = require('utf8');

class App extends Component {
  
  constructor(props){
    super(props);
    
    //setInterval(() => this.showProducts(),10);
    var eventCode = window.location.href.replace(window.location.origin, "");
    eventCode = eventCode.replace("/", "");
    

    this.state = { eventCode: eventCode, token: '', notvalidevent: true, isReady: false, userInfo: null, bookingInfo: null, validity: null, generalInfo: [], logo: 'light', tabTitle: '', userProfileOpened: false };
    
  }


  componentDidMount () {
    this.getEventInfo(this.state.eventCode);
  }

   convertUnicode = (input) => {
    return input.replace(/\u[0-9a-fA-F]{4}/g,function(a,b) {
      var charcode = parseInt(b,16);
      return String.fromCharCode(charcode);
    });
  }

  getEventInfo = (code) => {

    
    var sessiontoken = localStorage.getItem('session_token');
    if(sessiontoken === null){
      sessiontoken = '';
    }
    
    const requestOptions = {
      method: 'POST',
      cache: 'no-cache',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      }),
      body: JSON.stringify({
                code: code,
                token: sessiontoken
              }),
      json: true
      };
    
          const apiUrl = 'https://getfy-business.com/user/v7/code/';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     
      console.log(r);
      let themeManager = ThemeManager.getInstance();
      
      if(r.theme !== undefined && r.theme !== null){
        themeManager.setBackgroundColor(r.theme.background_color);
        themeManager.setSelectionColor(r.theme.selection_color);
        themeManager.setMainTextColor(r.theme.main_text_color);
        themeManager.setSecondaryTextColor(r.theme.secondary_text_color);
        themeManager.setButtonTextColor(r.theme.button_text_color);
        themeManager.setItemBackgroundColor(r.theme.item_background_color);
        themeManager.setErrorColor(r.theme.error_color);
      }


      var _backgroundColor = themeManager.getBackgroundColor();
      this.elaborateColorIntensity(_backgroundColor);


      var generalInfo = r.info; 
      var validity = r.info.details.validity;
     
      
      var redirect = r.redirect;

      if(redirect!=null){
        var newLocation = "/"+generalInfo.maincode+"/"+r.redirect;
        window.location.href = newLocation;
        return;
      }


      if(r.user == null){
        localStorage.removeItem('session_token');
        this.setState({ userInfo: null, notvalidevent: false, isReady: true, validity: validity, generalInfo: generalInfo});
      }else{
        var userInfo = [];
        userInfo.token = r.user.token;
        userInfo.birth = r.user.birth;
        userInfo.sex = r.user.sex;
        userInfo.name = r.user.name_array.name;
        userInfo.welcome = r.user.name_array.type;
        userInfo.phone = r.user.mobile.phone;
        userInfo.prefix = r.user.mobile.prefix;
        userInfo.info = r.user.info;
        
        var bookingInfo = null;
        if(r.booking!==null){
          bookingInfo = r.booking;
        }

       var tabTitle = generalInfo.details.name;
       if(generalInfo.details.place != ''){
        tabTitle = generalInfo.details.place;
       }
       

        this.setState({ userInfo: userInfo, notvalidevent: false, isReady: true, bookingInfo: bookingInfo, validity: validity, generalInfo: generalInfo, tabTitle: tabTitle});
      }
      
    }).catch(e => {
      this.setState({ notvalidevent: true, isReady: true, validity: 1, bookingInfo: null, tabTitle: 'Getfy'});
    });

   
  }

  elaborateColorIntensity = (bgColor) => {
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? this.setState({ logo: 'dark' }) : this.setState({ logo: 'light' });
  }

  openUserProfile = (open) => {
    this.setState({ userProfileOpened: open });
  }

  render(){
    
    let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

    return (
     
    <div>
    { this.state.isReady &&
      <div className="App" style={{  marginTop: '0px', backgroundColor: _backgroundColor}}>

      <MetaTags>
      <title>{this.state.tabTitle}</title>      
       <meta name="theme-color" content={_backgroundColor} /> 
       <meta name="description" content={this.state.tabTitle+" - prenota ora online - online booking"} />
       <meta property="og:title" content={this.state.tabTitle} />
      </MetaTags>
    
   {
      this.state.isReady && !this.state.userProfileOpened && this.state.bookingInfo===null && this.state.validity !== 0 
      ? (
        <Landing info={this.state.generalInfo.details} validity={this.state.validity} />
         )
       : (
           null
          )
    }


    {
      this.state.isReady && (this.state.userInfo!==null && this.state.userInfo!==undefined && this.state.userInfo.birth === 'ok' && this.state.userInfo.name !== '' && this.state.userInfo.email !== '' && this.state.userInfo.sex === 'ok')
      ? (
        <UserProfile open={this.openUserProfile} info={this.state.userInfo.info} />
        )
       : (
           null
          )
    }

    {
      this.state.isReady && (this.state.userInfo==null || this.state.userInfo==undefined || this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok')
      ? (
        <LangOption />
        )
       : (
           null
          )
    }

    {
      this.state.isReady && !this.state.userProfileOpened && (this.state.validity === 0 || this.state.bookingInfo!==null)
      ? (
        <BookingManager user={this.state.userInfo} booking={this.state.bookingInfo} info={this.state.generalInfo} />
        )
       : (
           null
          )
    }
  <br />
    { this.state.logo === 'dark' && 
    
    <img alt="Getfy" style={{marginTop: '20px', height: '15px'}} src={logoBlack} />
    }

    { this.state.logo === 'light' && 
     
    <img alt="Getfy" style={{marginTop: '20px', height: '15px'}} src={logoWhite} />
      }
      <br />
      <br />
   
    </div>  
    }  
    

   
    </div>
  );
    }
}

export default App;
//<img src={logo} className="Getfy-logo" alt="logo" /> 