import React, { Component } from 'react'
import QRCode from 'qrcode.react';
import ThemeManager from './ThemeManager';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import i18n from "i18next";

export class SingleBookingDetails extends Component {

    constructor(props){
        super(props);
        
        
        this.state = { bookRecap: props.info, backOption: props.backOption, userInfo: props.user, openCancel: false, isProcessing: false, bookingerror: false};
        
    }

    backButton = (e) => {
        this.props.back();
    }

    openInvoice = (e) => {
        const newWindow = window.open(this.state.bookRecap.stripe_proforma, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    confirmCancel = () => {
        this.setState({ openCancel: true })
    }

    toggle = () => {
        this.setState({ openCancel: false })
     }

    cancelBooking = () => {


        if(!this.state.isProcessing){
            this.setState({ isProcessing: true});
            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    bookingtoken: this.state.bookRecap.token,
                    type: 'single',
                  })
                };
              
                    const apiUrl = 'https://getfy-business.com/user/v7/booking/cancel';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
          
                if(r==null){
                    window.location.reload();

                }else{
                   //error
                    this.setState({ bookingerror: true, isProcessing: false});
                }
                
                this.toggle();
              }).catch(e => {
              
                this.setState({ bookingerror: true, isProcessing: false});
                this.toggle();
              });
        }

    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        const _buttonTextColor = themeManager.getButtonTextColor();

        return (
            <div>
  { this.state.bookingerror && <p style={{ marginTop: '20px', color: _errorColor }}>{i18n.t('request.error.reload')}</p> }
               
            <div style={{marginTop: '-20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
               
                <div style={{ marginTop: '-10px', color: _secondaryTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px' }}>
                {i18n.t('here.your.booking')}
                   
                    
                    </div>

                    { this.state.bookRecap.status === '1' &&
                    <div>
                    <p style={{ marginTop: '30px', color: _mainTextColor, fontWeight: 'bold', fontSize: '24px'}}> 
                    {this.state.bookRecap.code} 
                    </p>

                    <QRCode value={this.state.bookRecap.code} bgColor="white" fgColor="black" level="H" includeMargin={true}/>
                    </div>
                    }

                { (this.state.bookRecap.payment !== null && this.state.bookRecap.payment !== "0" && this.state.bookRecap.payment !== 0) &&
                    <div><div style={{ fontSize: '14px', marginTop: '30px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {i18n.t('your.deposit')} 
                    <p style={{ marginLeft:'10px', marginRight:'4px', color: _selectionColor, fontWeight: 'bold', fontSize: '16px'}}> 
                     {(parseFloat(this.state.bookRecap.payment)/100).toFixed(2)}
                    </p>
                    € 
                    </div>
                    <p onClick={this.openInvoice} style={{ cursor: 'pointer', marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _selectionColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    {i18n.t('show.receipt')}
                    </p>

                    <p style={{ marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _secondaryTextColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    {i18n.t('fiscal.receipt')} 
                    </p>
                    </div>
                }

                    
                    <br />
                    <br />
                    {
                        (this.state.bookRecap.payment == null || this.state.bookRecap.payment == "0" || this.state.bookRecap.payment == 0) &&
                        <div onClick={this.confirmCancel} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor, fontWeight: 'bold', marginTop: '5px'}}>{i18n.t('cancel.booking')}</div>     
                    }
                    
                    <br />
                    { 
                    <div onClick={this.backButton} style={{fontSize: '14px', cursor: 'pointer', color: _mainTextColor }}>{i18n.t('back')} </div>

                    }


                    <Drawer
                    open={this.state.openCancel}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('want.cancel')} <br />{i18n.t('your.booking')}</div>
                    </div>
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.cancelBooking}
            >{i18n.t('cancel')} </p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')} </div>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default SingleBookingDetails
